.group_e {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "a"
                        "b";
  grid-template-rows: max-content;
  gap: 2em;
  width: 100%;
  max-width: $xl;
  margin: 0 auto;

  &__box1 {
    grid-area: a;
    .article_teaser__horizontal {
      display: grid;
      grid-template-columns: 1fr 70%;
      grid-template-rows: max-content;
      grid-template-areas: "a b";
      column-gap: 1em;
      .article_teaser___vertical_box1{
        grid-area: b;
      }
      .article_teaser___vertical_box2{
        grid-area: a;
        padding: 1em;
      }
      @include xxx(xs, sm) {
        grid-template-columns: 1fr;
        grid-template-areas: "b"
                            "a"
      }
    }
  }

  &__box2 {
    grid-area: b;
    .article_teaser__horizontal {
      display: grid;
      grid-template-columns: 70% 1fr;
      grid-template-rows: max-content;
      grid-template-areas: "a b";
      column-gap: 1em;
      .article_teaser___vertical_box1{
        grid-area: a;
      }
      .article_teaser___vertical_box2{
        grid-area: b;
      }
      @include xxx(xs, sm) {
        grid-template-columns: 1fr;
        grid-template-areas: "a"
                            "b";
        .article_teaser___vertical_box2{
          padding: 1em;
        }
      }
    }
  }
}