// @import '../../src/scss/_mixins';
@import "author";
@import "audio";
@import "mobile_buttons";

.article {
  display: grid;
  grid-template-columns: 12em auto 300px;
  grid-template-rows: auto 1fr;
  column-gap: 1em;
  max-width: $xl;
  color: $black-color;
  width: calc(100% - 2em);
  padding-top: 2em !important;
  margin: 0 auto;
  transition: all 0.7s;

  &__part {
    &--0 {
      grid-area: 1 / 1 / span 1 / span 2;
      overflow: hidden;
    }

    &--1 {
      grid-area: 2 / 1 / span 1 / span 1;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    &--2 {
      grid-area: 2 / 2 / span 1 / span 1;

      iframe[src*="youtube"] {
        width: 100%;
      }
    }

    &--3 {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      grid-area: 1 / 3 / span 2 / span 1;
    }
  }
  &__promo {
    margin-bottom: 1em;
  }
  &__title {
    font-size: 2.8em;
    margin: -0.2em 0 0 0;
    font-weight: 900;
    line-height: 1.5em;
    @include xxx(xs, sm, smm, md) {
      font-size: 2em;
    }
    .fas, .fab {
      color: $base-color;
      margin-right: 0.3em;
    }
  }

  &__subtitle {
    // font-family: Delo;
    font-size: $md-font;
    // line-height: 115%;
    font-weight: 300;
    // font-style: italic;
    // color: $darker-50-color;
    margin: 0.5em 0;
    line-height: 1.2em;
    @include xxx(xs, sm, smm, md) {
      font-size: 1.4em;
      // margin-bottom: 0.5em;
    }
  }

  &__date {
    display: block;
    font-size: 0.9em;
    margin: 0 auto 1em auto;
    text-align: center;
    width: 100%
  }

  &__main_image {
    width: 100%;
    cursor: pointer;
    position: relative;
  }
  &__gallery_badge {
    position: absolute;
    color: $white-color;
    font-weight: bold;
    font-size: $sm-font;
    left: 1em;
    bottom: 1em;
  }

  &__img_tag {
    width: 100%;
  }

  &__image_caption {
    font-size: 0.85em;
    font-style: italic;
    // color: $darker-50-color;
    margin-bottom: 1em;
    padding-top: 0.3em;
  }

  &__section_title {
    font-size: 1.2em;
    font-weight: normal;
    margin: 0 0 0.3em 0;
    font-weight: 600;
  }

  &__teaser {
    margin-bottom: 2em;
    position: relative;
    &-gradient{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 150px;
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 87%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 87%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 87%,rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
    }
  }

  &__metadata_sticky_container {
    top: 6em;
    position: sticky;
    align-self: flex-start;
    max-width: 100%;
    overflow: hidden;
  }

  &__sidebar_sticky_container {
    top: 1em;
    position: sticky;
    align-self: flex-start;
  }

  &__metadata {
    min-width: 100%;
    text-align: center;
    padding-bottom: 1em;
  }

  .article__metadata--share {
    padding-bottom: 0.5em;
    h4.widget_title_light {
      margin-bottom: 0.5em;
    }
  }
}

@media screen and (max-width: $xl + 2) {
  .article {
    padding: 0 1em;
    transition: all 0.7s;
    //max-width: 945px;
  }
}

@media screen and (max-width: $lg - 0.1) {
  .article {
    grid-template-columns: auto 300px;
    padding: 0 1em;
    &__part {
      &--0 {
        grid-area: 1 / 1 / span 1 / span 2;
      }

      &--1 {
        grid-area: 2 / 1 / span 1 / span 2;
      }

      &--2 {
        grid-area: 3 / 1 / span 1 / span 1;
      }

      &--3 {
        display: flex;
        grid-area: 3 / 2 / span 1 / span 1;
      }
    }

    &__metadata_sticky_container {
      display: none;
    }
  }
}

@media screen and (max-width: $md - 0.1) {
  .article {
    grid-template-columns: 100%;
    margin: 0 auto;
    padding: 1em 1em 0em 1em;

    &__part {
      &--0 {
        grid-area: 1 / 1 / span 1 / span 1;
      }

      &--1 {
        grid-area: 2 / 1 / span 1 / span 1;
      }

      &--2 {
        grid-area: 3 / 1 / span 1 / span 1;
      }

      &--3 {
        display: flex;
        grid-area: 4 / 1 / span 1 / span 1;
      }
    }
  }
  /* Twitter being quite idiotic with inline styles */
  twitter-widget {
    width: auto !important;
    min-width: auto !important;
  }
}


/* Document content */
.article {
  a, &__link {
    color: $base-color;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  &__content {
    margin-bottom: 2em;
    font-size: 1.1em;
    line-height: 160%;
    overflow: hidden;
    h1 {
      font-size: 1.2em;
      line-height: 1.2em;
      font-weight: 600;
      margin: 0.5em 0 0 0;
    }

    h2 {
      font-size: 1.2em;
      line-height: 1.2em;
      font-weight: 600;
      margin: 0.5em 0 0 0;
    }

    h3 {
      font-size: 1.2em;
      line-height: 1.2em;
      font-weight: 600;
      // color: $darker-50-color;
      margin: 0.5em 0 0
    }
  }

  &__content-image {
    width: 100%;
    cursor: pointer;
    @media screen and (min-width: $sm) {
      &--l30 {
        float: left;
        width: 30%;
        padding: 0.3em 1em 1em 0;
      }
      &--r30 {
        float: right;
        width: 30%;
        padding: 0.3em 0 1em 1em;
      }
      &--l50 {
        float: left;
        width: 50%;
        padding: 0.3em 1em 1em 0;
      }
      &--r50 {
        float: right;
        width: 50%;
        padding: 0.3em 0 1em 1em;
      }
    }
  }

  &__img-tag-visible {
    width: 100%;
    display: block;
    margin-bottom: 0.3em;
    @media screen and (min-width: $md) {
      width: 100%;
      margin-top: 1em;
      margin-left: 0em;
      margin-bottom: 0.3em;
    }
  }

  &__image-caption {
    font-size: 0.7em;
    font-style: italic;
    line-height: normal;
    // color: $darker-50-color;
  }

  &__infobox-citat {
    color: $darker-70-color;
    font-style: italic;
    padding-left: 2em;
    margin-bottom: 1em;

    &:before {
      position: absolute;
      color: $base-color;
      content: "\f10e";
      font-size: 2em;
      font-weight: 900;
      font-family: "Font Awesome 5 Free";
      margin-left: -1.4em;
    }
  }

  &__infobox-poudarek {
    box-sizing: border-box;
    background: $base-color-10;
    padding: 1em;
  
    .ib_title{
      display: block;
      font-weight: bold;
      font-size: 1em;
      line-height: 1.2;
      margin-bottom: 0.5em;
    }
  }

  &__infobox-text {
    color: $darker-70-color;
    font-style: italic;
    padding: 0.5em 0 0.5em 1.5em;
    border-left: 0.4em solid $base-color;
    display: inline-block;
    @include xxx(xs, sm, smm, md) {
      padding: 0.5em 0 0.5em 0.5em;
      .ib_text {
        margin-left: 1em;
      }
    }
  }

  .twitter-tweet iframe {
    width: 100% !important;
  }

  .article_teaser__title_link {
    box-sizing: border-box;
    width: 100%;
    font-size: 1em;
    line-height: 1.2em;
    margin: 0;
    padding-top: 0.3em;
    text-decoration: none;
    color: black;
    font-weight: inherit;
  }
}

.article_bottom {
  width: calc(100% - 2em);
  max-width: 1280px;
  margin: 0 auto 1em auto;
  .group_c {
    width: 100%;
  }
  .group_carousel_holder_3 {
    width: 100%;
    margin: 0 auto 1em auto;
    .carousel-item {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  
  .group_carousel_holder_4 {
    width: 100%;
    margin: 0 auto 1em auto;
    .carousel-item {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
}

.sticky-article {
  top: 4em !important;
}


.article_bottom .dark_theme {
  display: block;
  margin-bottom: 1em;
  @include xxx(xs, sm) {
    display: none;
  }
}

.article .dark_theme {
  display: none;
  @include xxx(xs, sm) {
    display: block;
    margin-bottom: 1em;
    .group_carousel_holder_4 {
      width: 100%;
    }
  }
}

.article .dark_theme .group_c {
  @include xxx(xs, sm) {
    grid-template-columns: repeat(1, 1fr);;
    grid-template-areas: "a"
                           "b"
                           "c"
                           "d";
  }
}

.article__part--2{
  .recommended_articles{
    display: none;
    @include xxx(xs, sm) {
      display: block;
    }
  }
}

.article__part--3{
  .recommended_articles{
    display: block;
    @include xxx(xs, sm) {
      display: none;
    }
  }
}