.group_carousel_holder_3 {
  position: relative;
  margin: 0 auto 1em auto;
  padding: 0em;

  @include xxx(xs, sm) {
    .article_teaser__article_supertitle {
      display: none;
    }

    .article_teaser__title_text {
      font-size: 1em;
      line-height: 1em;
    }

    .article_teaser__subtitle_text {
      line-height: 1em;
      font-size: 0.8em;
    }
  }

  .group_carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);;
    grid-template-rows: max-content;
    grid-template-areas: "a a b c";
    gap: 0em;
    width: calc(100% - 2em);
    max-width: calc(1280px - 2em);
    margin: 0 auto 0em auto;
    padding: 1em;

    @include xxx(xs, sm, smm, md) {
      grid-template-columns: repeat(2, 1fr);;
      grid-template-areas: "a a"
                           "b c";
    }

    .box_item {
      position: relative;
      overflow: hidden;
      @include xxx(xs, sm) {
      }
      @include xxx(smm) {
      }
    }

    .box1 {
      grid-area: a;
    }

    .box2 {
      grid-area: b;
      .article_teaser__vertical{
        .article_teaser__title_link h3{
          padding-left: 0.5em;
          padding-right: 0.5em;
        }
      }
    }

    .box3 {
      grid-area: c;
      .article_teaser__vertical{
        .article_teaser__title_link h3{
          padding-left: 0.5em;
          padding-right: 0.5em;
        }
      }
    }
  }
}

.group_carousel.dark {
  background-color: $darker-80-color;

  .article_teaser__title_link {
    color: $offwhite-color;
  }
}

.group_carousel.light {
  width: 100%;
  max-width: 1280px;
  padding: 0em;
  background-color: white;
}
