@use "sass:math";

.related_articles {
  margin-bottom: 2em;
  &__articles_grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin-bottom: 1em;
    @include breakpoint(xxs) {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0em;
      grid-row-gap: 1em;
    }
  }

  &__content, a { // A overrides article content design
    display: block;
    box-sizing: border-box;
    color: $black-color;
    text-decoration: none;
    width: 100%;
    line-height: 1.2;
  }

  &__image {
    width: 100%;
    padding-top: math.div(100%, 16) * 9;
    margin-bottom: 0.2em;
    background-size: cover;
  }

  &__title {
    font-size: $sm-font;
    display: inline-block;
  }

  @include xxx(xs, sm, smm){
    .article_teaser__subtitle_text {
      display: none;
    }
  }
}