.materializecss {
  text-align: initial;
  color: initial;
  width: 100%;
  @import "node_modules/materialize-css/sass/components/color-variables";
  $primary-color: $base-color !default;
  $secondary-color: $darker-50-color !default;

  .tabs a {
    text-decoration: none;
  }

  .modal-content {
    background-color: $white-color;
  }

  @import "node_modules/materialize-css/sass/materialize";

  .datepicker-modal {
    max-width: 665px;
    overflow: hidden;

    .datepicker-date-display {
      flex: 1 auto;
      background-color: $base-color-80;
      color: #fff;
      padding: 20px 22px;
      font-weight: 500;
    }

    td.is-today {
      color: $base-color;
    }

    td.is-selected {
      color: white;
      background-color: $base-color;
    }
  }

  .datepicker-controls .select-month input {
    width: 100px;
  }

  .datepicker-day-button {
    position: relative;
    left: -5px;
  }
}

// Namespacing issue
@import "node_modules/materialize-css/sass/components/tooltip";
.material-tooltip {
  background-color: $base-color;
  min-height: auto;
  border: 1px solid $white-color;
}

$dropdown-bg-color: transparent !default;
$dropdown-hover-bg-color: $offwhite-color !default;
$dropdown-color: $black-color !default;
$dropdown-item-height: auto;
$off-black: $black-color;
@import "node_modules/materialize-css/sass/components/dropdown";
