@use "sass:math";

.tag {
  margin: 0 auto;
  padding: 2em 1em 0 1em;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: max-content;
  grid-template-areas: "a b";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  @include xxx(xs, sm, smm){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b";
  }
  @include xxx(lg){
    //max-width: 945px;
  }

  &__content {
    display: grid;
    grid-template-columns: 35% 1fr;
    grid-template-rows: auto;
    margin-bottom: 2em;
    @include breakpoint(xs) {
      grid-template-columns: 1fr;
      grid-column-gap: 0em;
      grid-row-gap: 1em;
    }
    @include breakpoint(sm) {
      grid-template-columns: 1fr;
      grid-column-gap: 1em;
      grid-row-gap: 1em;
    }
    @include breakpoint(mdd) {
      grid-template-columns: 35% 1fr;
      grid-column-gap: 1em;
      grid-row-gap: 1em;
    }
  }

  &__about {
    margin-bottom: 2em;
  }

  &__image {
    grid-area: left;
    width: calc(100% + 2em);
    position: relative;
    left: -1em;
    padding-top: math.div(100%, 16) * 9;
    margin: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include breakpoint(sm) {
      width: 100%;
      left: 0;
    }
  }

  &__articles {
    margin-top: 0px;
    margin-bottom: 1em;
  }

  .pagination {
    margin-bottom: 2em;
    margin-top: 1em;
  }

  .social_icons {
    a {
      display: inline-block;
    }

    .social__icon_container {
      margin: 1em 1em 1em 0;
    }
  }
  
  @include xxx(md, mdd, lg){
    .opinions_wide__wrapper {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:   "a"
                         "b"
                         "c"
                         "d";
    }
    .opinions_wide__content {
      grid-template-columns: 29% 1fr;
      grid-template-areas: "a b";
      grid-template-rows: auto;
    }
    
    .opinions_wide__content_left a {
      display: initial;
    }
  }
}