.group_title_c {
  width: 100%;
  max-width: $xl;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  text-decoration: none;
  min-height: 1.7em;

  @include xxx(md, mdd, lg) {
    //max-width: 945px;
  }
  h2 {
    margin: 0;
  }
  &__title {
    font-size: 1em;
    line-height: 1em;
    color: $base-color;
    margin: 0;
    position: relative;
    font-family: 'Mulish';
    font-weight: 900;
    display: inline-block;
    text-decoration: none;
  }
  .icon-Artboard-37 {
    &:before {
      color: $base-color;
      font-size: 1em;
      margin-right: 0.5em;
      display: inline-block;
    }
  }
}

div.group_title {
  cursor: auto;
}