.group_carousel_holder_vertical_4_wrapper {
  width: calc(100% + 2em);
  position: relative;
  @include xxx(xs, sm, smm) {
    display: none;
  }
}

.group_carousel_holder_vertical_4 {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: calc(1280px + 2em);
  margin: 0 auto;
  
  @include xxx(xs, sm) {
    .article_teaser__article_supertitle {
      display: none;
    }
    
    .article_teaser__title_text {
      font-size: 1em;
      line-height: 1em;
    }
    
    .article_teaser__subtitle_text {
      line-height: 1em;
      font-size: 0.8em;
    }
  }
  
  .group_carousel {
    display: grid;
    grid-template-columns: repeat(1, 1fr);;
    grid-template-rows: max-content;
    grid-template-areas: "a"
                           "b"
                           "c"
                           "d";
    gap: 1em;
    width: 100%;
    max-width: calc(1280px - 2em);
    margin: 0 auto;
    padding-bottom: 0.9em;
    
    .box1 {
      grid-area: a;
    }
    
    .box2 {
      grid-area: b;
    }
    
    .box3 {
      grid-area: c;
    }
    
    .box4 {
      grid-area: d;
    }
  }
  
  .slick-dots {
    padding-bottom: 0em;
  }
}

.group_carousel.dark {
  background-color: $darker-80-color;
  
  .article_teaser__title_link {
    color: $offwhite-color;
  }
}

.group_carousel.light {
  width: 100%;
  max-width: 1280px;
  padding: 0em;
  background-color: white;
}
.group_title_b__carousel_vertical_title{
  padding-bottom: 1em;
}