.group_vertical_12 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: max-content;
  gap: 1em;
  width: 100%;
  max-width: $xl;
  margin: 0 auto 1em auto;
  
  &__box1 .article_teaser__title_link h3 {
    min-height: 4em;
  }
}