.slider-item {
  width: calc(100% - 2em) !important;
  margin: 0 auto;
  position: relative;
  left: 1em;
}

.slick-dots {
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  line-height: 1em;
  min-height: 1em;
  li {
    display: inline;
    margin: 0 0.5em;
  }
  li a {
    color: $grey-color;
    font-weight: 900;
    font-family: Mulish;
    font-size: 1em;
    cursor: pointer;
    &:hover{
      color: $black-color;
    }
  }
  li.slick-active a {
    color: $base-color;
    &:hover{
      color: $base-color;
    }
  }
}