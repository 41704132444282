.last_articles {
  color: $black-color;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas: "a"
                       "b"
                       "c"
                       "d"
                       "e";
  grid-template-rows: repeat(6, auto) 1fr;
  gap: 1em;

  .group_title_b__last_articles_title { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }
  &__box4 { grid-area: e; }
  

  &__content {
    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
    }
  }

  &__box {
    display: grid;
    grid-auto-columns: min-content;
    grid-template-columns: minmax(1px, 40px) 1fr;
    grid-template-rows: auto;
    column-gap: 1em;
    row-gap: 2em;
    color: $black-color;
    a.last_articles__content {
      text-decoration: none;
      color: $black-color;
      font-family: Mulish;
      font-weight: 700;
      font-size: 0.9em;
      line-height: 1em;
    }
  }

  &__time {
    display: block;
    box-sizing: border-box;
    line-height: 1.2em;
    font-size: 1em;
    color: $secondary-color-50;
    font-family: Mulish;
    font-weight: 700;
  }

  &__title {
    font-size: 1.1em;
    line-height: 1.2em;
    color: $black-color;
    font-family: Mulish;
    font-weight: 700;
  }

  &__subtitle {
    color: $black-color;
    line-height: 1.2em;
    font-size: 1em;
    font-weight: 100;
    @include xxx(xs, sm){
      display: none;
    }
  }
  a.last_articles__more{
    display: block;
    color: $base-color;
    font-size: 1em;
    width: auto;
    height: 1em;
    line-height: 1em;
    font-weight: 900;
    text-decoration: none;
    margin: 0;
    text-align: center;
    font-family: Mulish;
    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
    }
  }
}

.article__container .last_articles,
.tag .last_articles,
.author .last_articles,
.search .last_articles {
  @include xxx(md, mdd, lg) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d"
                         "e";
  }
}
