$height: 20em;

.last_print {
  max-width: $xl;
  width: calc(100% - 2em);
  text-align: center;
  margin: 0 auto 0 auto;
  @include xxx(lg){
    //max-width: 945px;
  }
  /* super container hack */
  &__super_container { display: none; padding-top: 1em}
  @include xxx(xl, xxl) { &__xl { display: block; } }
  @include xxx(lg, lgg) { &__lg { display: block; } }
  @include xxx(md, mdd) { &__md { display: block; } }
  @include xxx(smm) { &__sm { display: block; } }
  @include xxx(xs, sm) { &__xs { display: block; } }
  /* end super container hack */
  .carousel {
    height: $height !important;
  }
  &__page {
    display: flex;
    height: $height !important;
    justify-content: space-between;
  }
  &__item {
    display: inline-block;
  }
  &__dateX {
    font-family: DeloNormalBold;
    box-sizing: border-box;
    padding: 0.3em 0.2em 0.2em 0.5em;
    margin-bottom: 0.5em;
    text-align: left;
    width: 100%;
    background: $base-color;
  }
  &__header {
    font-family: DeloNormal;
    //box-sizing: border-box;
    //padding: 0.3em 0.2em 0.2em 0.5em;
    margin-bottom: 0.5em;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid $base-color;
    //background: $base-color;
  }
  &__date {
    display: inline-block;
    font-size: 85%;
    line-height: normal;
    color: $white-color;
    background-color: $base-color;
    padding: 0.1em 0.3em;
  }
  &__issue {
    display: inline-block;
    vertical-align: top;
    height: 220px;
  }
  &__image {
    display: inline-block;
    width: 140px;
    height: 12em;
    border: 1px solid lightgrey;
  }
  &__img {
    display: none;
  }

  .indicators {
    zoom: 2;
    .indicator-item {
      background-color: $base-color-20 !important;
      &.active {
        background-color: $base-color !important;
      }
    }
  }
}

// Special view in left column
.group_b__box_module3 .last_print {
  width: 100%;
  //@include xxx(xs, sm, smm, md, mdd, lg, lgg) { display: none; }
}