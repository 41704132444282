.social_icons_mobile_footer {
  position: sticky;
  bottom: 0;
  background: $base-color;
  padding: 0 1em;
  z-index: 1000;
  display: none;
  @include xxx(xs, sm, smm, md, mdd) {
    display: block;
  }

  .materializecss .modal {
    width: 300px;
    margin: 0 auto
  }

  .social_icons_mobile {
    @include xxx(xs, sm, smm, md, mdd) {
      display: block;
      float: left;
      margin-bottom: 0;
    }
  }

  // By Robert Schmitzer, 23.3.22
  .other_icons_mobile {
    @include xxx(xs, sm, smm, md, mdd) {
      display: block;
      float: left;
      margin-bottom: 0;
    }
  }

  .delo-mobile-float__button--play {
    position: absolute;
    border: 0.5em solid $base-color;
    top: -1em;
    right: 1em;
    box-shadow: none;
    & .fas {
      //color: $base-color !important;
    }
  }

  .social {
    &__icon_container {
      margin: 0 .5em 0 0;
      transform: none;
      background: transparent;
    }
    &__icon {
      background-color: white !important;
    }
  }

  // By Robert Schmitzer, 23.3.22
  .other {
    &__icon_container {
      display: inline-block;
      margin: 0.25em .5em 0 0;
      transform: none;
      background: transparent;
    }
    &__icon {
      height: 2em;
      width: 2em;
      margin: 0 .25em 0 0;

      i {
        display: block;
        cursor: pointer;
        color: white;
        font-size: 2em;
        text-decoration: none;
        color: white !important;
      }
      //background-color: white !important;
    }
  }
}

