.group_a_category {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas: "a a a"
                       "b c d";
  gap: 1em;
  width: 100%;
  margin-bottom: 2em;

  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }

  @include xxx(xs){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d";
  }

  @include xxx(sm, smm){
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a a"
                         "b b"
                         "c d";
  }
}