.pagination {
  width: 100%;
  .page-item {
    display: inline-block;
    margin-right: 0.5em;
  }
  &.materializecss .btn {
    color: $base-color;
    background-color: $white-color;
    margin-bottom: 0.5em;
    &.btn-primary {
      color: $white-color;
      background-color: $base-color;
    }
  }
}