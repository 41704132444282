.group_j {
  height: auto;
  margin-bottom: 2em;
  .group_j__box{
    position: relative;
  }
  .article_supertitle {
    color: white !important;
  }
  .group_j__inner_box{
    top: 1em;
    position: absolute;
    right: 1em;
    width: 40%;
    @include xxx(xs, sm, smm, md) {
      position: relative;
      width: calc(100% - 2em);
      top: 1em;
      left: 1em;
    }
    
    .article_teaser_wrapper{
      margin-bottom: 1em;
      .box_shadow{
        background-color: rgba(0, 0, 0, 0.6);
        &:hover{
          .article_teaser__title_text{
            color: $base-color;
          }
        }
      }
      .article_teaser__title_text{
        color: white;
      }
      .article_supertitle{
        color: white !important;
      }
    }
  }
  &__button_more {
    padding: 1em 1em 1em 1em;
    display: block;
    color: $base-color;
    font-size: 1em;
    width: auto;
    height: 1em;
    line-height: 1em;
    font-weight: 900;
    text-decoration: none;
    margin: 0 1em;
    text-align: center;
    font-family: Mulish;
    text-transform: uppercase;
    border-top: 1px solid $silver-color;
    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
    }
  }
}