.g-recaptcha {
  @include xxx(xs) {
    transform:scale(0.77);
    transform-origin:0 0;
    margin: 0 -1.5em;
    padding: 1em 0 0 0;
  }
}

[href="#modal_logout"] {
  color: $ternary-color;
}

@include xxx(xs, sm, smm) {
  .modal-content {
    .btn {
      display: inline-block;
      margin-bottom: 1em;
    }
  }
}