.newsletter {
  margin-bottom: 2em;
  &__btn {
    border-radius: 25px!important;
    text-transform: uppercase;
    font-family: Poppins;
    font-weight: 400!important;
    letter-spacing: 1px;
    font-size: 18px!important;
    padding: 3px 28px!important;
    color: $white-color;
    background-color: $base-color;
    margin-top: -2em !important;
    &:hover {
      text-decoration: none !important;
    }
  }
}