.group_section_right {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content;
  grid-template-areas: "a a b x"
                       "c d e x";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  margin: 0 auto 1em auto;
  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }
  &__box5 { grid-area: e; }

  &__box_module1 {
    grid-area: j;
    background: $darker-80-color;
    color: $offwhite-color;
    .article_teaser__title_link{
      color: $offwhite-color;
    }
  }
  &__box_x { grid-area: x; }

  @include xxx(xs, sm, smm){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d"
                         "e"
                         "x";
  }

  @include xxx(md, mdd, lg){
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "a a b"
                         "c d e"
                         "x x x";
    //max-width: 945px;
  }
}