/* OLD */
/*
#pos-banner-first-block, #pos-banner-second-block,
#pos-banner-third-block, #pos-banner-forth-block, #pos-banner-fifth-block {
  width: calc(100% - 2em);
  margin: 0 auto 1em auto;
  max-width: $xl;
  @include xxx(md, mdd, lg){
    max-width: 945px;
  }
}
 */

.ad_holder__300_250{
  width: 100%;
  background-color: $offwhite-color;
  min-width: 300px;
  min-height: 250px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

#pos-wallpaper-left {
  float: right;
  top: 3.5em;
  min-width: 160px;
  height: 600px;
}
#pos-wallpaper-right {
  float: left;
  top: 3.5em;
  min-width: 160px;
  height: 600px;
}
// ------------------------------------------------------------------------

.container__content{

}
.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  @include xxx(xs, sm, smm, md, mdd, lg) {
    display: block;
    .container__content{
      //max-width: calc(945px + 2em);
      margin: 0 auto;
    }
  };
  @include xxx(xl, xxl) {
    position: relative;
    width: 1280px;
    display: block;
  };

  &__right {
    position: absolute;
    display: none;
    height: 100%;
    top: 0;
    left: calc(100% + 1em);
  
    @media (min-width: 1650px) {
      display: block;
    }
  }

  &__left {
    position: absolute;
    display: none;
    height: 100%;
    top: 0;
    right: calc(100% + 1em);
    @media (min-width: 1650px) {
      display: block;
    }
  }

  &__abs_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

#pos-banner {
  margin: 0 auto;
  position: relative;
  background-color: white;
  max-width: 100%;
  overflow: hidden;
  @include xxx(xs, sm, smm, md, mdd) {
    // top: 1.5em;
    //margin-bottom: 2em;
  }

  &__article {
    top: 0;
    margin-bottom: 1em;
    background-color: white;
  }
}

#pos-videoscroll {
  margin-bottom: 1em;
  width: 100%;
  overflow: hidden;
}

#pos-sidebar-bottom {
  padding-bottom: 0 !important;
  width: 100%;
  overflow: hidden;
}

#pos-sidebar-top {
  padding-bottom: 0 !important;
  overflow: hidden;
  width: 100%;
}

#pos-banner-bottom {
  margin-bottom: 2em;
  width: 100%;
  overflow: hidden;
}

#pos-banner-middle {
  width: 100%;
  overflow: hidden;
}

.iAdserver[data-iadserver-zone='250']{
  width: 100%;
  overflow: hidden;
  margin-top: 1em;
}