.group_b2 {
  display: grid;
  row-gap: 1em;
  column-gap: 2em;
  grid-template-areas: "a b c";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  margin: 0 auto;
  max-width: $xl;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 2em;
  
  .group_title_c {
    position: relative;
    left: -1em;
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    width: calc(100% + 1em);
    .group_title_c__title{
      color: white;
    }
    .icon-Artboard-37 {
      &:before{
        color: white;
      }
    }
  }
  
  &__box_1 {
    grid-area: a;
    .group_title_c {
      background-color: black;
    }
  }
  &__box_2 {
    grid-area: b;
    .group_title_c {
      background-color: $base-color;
    }
  }
  &__box_3 {
    grid-area: c;
    .group_title_c {
      background-color: #555555;
    }
    .group_vertical_12 {
      @include xxx(md, mdd, lg) {
        padding-top: 1em;
        column-gap: 2em;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "a b"
                              "a c"
                              "a d"
                              "a e"
                              "a f";
        &__box1 {
          grid-area: a;
        }
        &__box2 {
          grid-area: b;
        }
        &__box3 {
          grid-area: c;
        }
        &__box4 {
          grid-area: d;
        }
        &__box5 {
          grid-area: e;
        }
        &__box6 {
          grid-area: f;
        }
      }
    }
  }
  
  &__send_content{
    background-color: #555555;
    padding: 1em 2em;
    color: white;
    font-family: Mulish;
    font-weight: 900;
    text-decoration: none;
    display: grid;
    column-gap: 1em;
    grid-template-areas: "a b";
    grid-template-rows: max-content;
    
    .smileyicon{
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      .icon-Artboard-9 {
        &:before{
          font-size: 4.4em;
        }
      }
    }
    .smileytext{
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  
  @include xxx(xs, sm, smm) {
    grid-template-areas: "a"
                         "b"
                         "c";
    grid-template-columns: repeat(1, 1fr);
  }
  
  @include xxx(md, mdd, lg) {
    grid-template-areas: "a b"
                         "c c";
    grid-template-columns: repeat(2, 1fr);
  }
}