.group_vertical_3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  grid-template-areas: "a"
                       "b"
                       "c";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  padding: 1em;
  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
}