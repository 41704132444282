.container {
  .opinions {
    width: 100%;
    margin-bottom: 1em;

    &__image {
      width: 100%;
      padding-top: 100%;
      margin: 0;
      border: none;
      background-size: cover;
      border-radius: 50%;
    }

    &__image.squill {
      background-size: 70%;
      background-repeat: no-repeat;
      background-color: $base-color-10;
      background-position: center;
    }

    &__content {
      margin-bottom: 1em;
    }

    &__content_left {
      float: left;
      width: 35%;
    }

    &__content_right {
      float: right;
      width: calc(65% - 1em);
      padding-left: 1em;
    }

    &__blue_title {
      font-weight: 500;
      font-size: $sm-font;
      margin-bottom: 0.5em;
      color: $base-color;
      display: inline-block;
      text-decoration: none;
    }

    &__title {
      font-weight: 600;
      font-size: $smm-font;
      color: $darker-80-color;
      margin-bottom: 0.5em;
      display: block;
      text-decoration: none;
    }

    &__text {
      font-size: $sm-font;
      color: $darker-80-color;
      display: block;
      text-decoration: none;
    }

    &__more {
      float: right;
      text-decoration: none;
    }
  }
}