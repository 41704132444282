.footer_top__wrapper{
  background-color: white;
  margin-top: 5em;
}
.footer_top {
  background-color: white;
  display: grid;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  gap: 1em;
  grid-template-areas: "a b";
  grid-template-columns: repeat(2, 1fr);

  @include xxx(xs, sm, smm, md, mdd) {
    grid-template-areas: "b"
                         "a";
    grid-template-columns: 1fr;
  }

  &__box {
    &_1 {
      grid-area: a;
      padding: 5em;
      @include xxx(xs, sm, smm, md, mdd) {
        padding-top: 0;
        padding-bottom: 2em;
      }
    }

    &_2 {
      grid-area: b;
      positioin: relative;
      img{
        width: 100%;
        position: relative;
        max-width: 630px;
        margin: 0 auto;
        top: -3em;
      }
    }
  }

  &__title{
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 300;
    font-size: 2.3em;
    line-height: 1;
    max-width: 300px;
    display: block;
  }
}