.article {
  &__audio_control {
    display: inline-block;
    color: $base-color;
    font-size: 2.2em;
    text-decoration: none !important;
    transition: all 0.5s ease;
    //cursor: pointer;
    overflow: hidden;
    &:hover {
      transform: scale(1.25);
      transition: all 0.3s ease;
    }
  }
  &__audio_length {
    text-align: right;
    margin-top: 0.5em;
  }
  #Dp {
    margin: 0 0.5em;
  }
}

#modal_audio {
  width: calc(240px + 2em);
  margin: 0 auto;
  top: 30% !important;
  .article__audio_control {
    font-size: 3em;
    height: 1em;
  }
  i::before {
    position: relative;
    top: 0.3em;
  }
  .article__audio_controls {
    padding: 0 !important;
    width: calc(240px + 2em);
    padding: 1em
  }
}