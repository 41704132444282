h4.widget_title_light {
  font-weight: normal;
  color: $base-color;
  text-transform: uppercase;
  padding: 0.2em 0;
  border-top: 1px solid $darker-20-color;
  border-bottom: 1px solid $darker-20-color;
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 0px;
  display: block;
}

h4.widget_title {
  background-color: $base-color;
  color: white;
  font-size: 1.2em;
  padding: 0;
  width: auto;
  height: 2.2em;
  line-height: 2.2em;
  font-weight: 600;
  text-decoration: none;
  margin: 0;
  text-align: center;
  font-family: DeloNormalBold;
}

.dark_theme {
  background-color: #262626;
  padding: 0.2em 1em 0em 1em;

  .group_title_a {
    color: white;
    width: 100%;

    span {
      color: white;

      &:before {
        color: $base-color;
      }
    }

    span.icon-Artboard-37 {
      &:before {
        color: white;
      }
    }
  }

  .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background: #262626;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(38, 38, 38, 0.78802) 25%, #262626 100%, #262626 100%);
    padding: 0px;
  }

  .group_carousel_holder_4 .carousel-item {
    background-color: #262626;
  }

  .article_teaser__title_text {
    color: white;
  }

  .article_teaser__subtitle_text {
    color: white;
  }
}

.widget_wrapper {
  position: relative;
  background-color: $offwhite-color;
  margin-bottom: 1em;
  width: 100%;
}

.fb_iframe_widget {
  display: block !important;
  position: relative;
  margin: 0 auto 1em auto !important;
  padding: 0 !important;
}

#home_sb_fb {
  width: 100%;
}

.box_shadow {
  background: #fff;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow .25s;
  -moz-transition: box-shadow .25s;
  -o-transition: box-shadow .25s;
  -webkit-transition: box-shadow .25s;
  cursor: pointer;
  height: 100%;
  &:hover {
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.6);
    transition: box-shadow .25s;
    -moz-transition: box-shadow .25s;
    -o-transition: box-shadow .25s;
    -webkit-transition: box-shadow .25s;

    .article_teaser__title {
        color: $base-color;
      }
  }
}

.box_shadow_1 {
  background: #fff;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow .25s;
  -moz-transition: box-shadow .25s;
  -o-transition: box-shadow .25s;
  -webkit-transition: box-shadow .25s;
  cursor: pointer;
  height: 100%;
  &:hover {
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.6);
    transition: box-shadow .25s;
    -moz-transition: box-shadow .25s;
    -o-transition: box-shadow .25s;
    -webkit-transition: box-shadow .25s;
  }
}

.group_a .box_shadow {
    &:hover {
      .article_teaser__title_link {
        width: calc(100% - 1em);
        padding-left: 0.3em;
        padding-top: 0.3em;
        text-decoration: none;
        padding-right: 0.2em;
        background-color: $base-color;
      }

      .article_teaser__title {
        background-color: $base-color;
      }

      .article_supertitle {
        font-size: 0.8em;
        font-family: Merriweather;
        color: white;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 0.5em;
      }
  }
}