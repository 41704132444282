.author {
  margin: 0 auto;
  padding: 2em 1em 0 1em;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: max-content;
  grid-template-areas: "a b";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  @include xxx(xs, sm, smm){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b";
  }
  @include xxx(lg){
    //max-width: 945px;
  }

  &__section{

  }

  &__content {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
    gap: 1em;
    margin-bottom: 2em;
    @include xxx(xs) {
      grid-template-columns: 1fr;
      grid-column-gap: 0em;
    }
  }

  &__image {
    grid-area: left;
    width: 100px;
    height: 98px;
    position: relative;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  &__articles {
    margin-top: 0px;
    margin-bottom: 1em;
  }

  .pagination {
    margin-bottom: 2em;
    margin-top: 1em;
  }

  .social_icons {
    a {
      display: inline-block;
    }

    .social__icon_container {
      margin: 1em 1em 1em 0;
    }
  }
}