.tags {
  margin-bottom: 2em;
  &__btn {
    margin-bottom: 0.5em;
    background-color: $base-color !important;
    &:hover {
      text-decoration: none !important;
      opacity: 0.8;
    }
  }
}