.subscribe_footer{
  height: auto;
  margin-bottom: 2em;

  .editions_holder{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content;
    gap: 1em;
    width: 100%;
    max-width: $xl;
    margin: 0 auto 1em auto;
    max-height: 180px;
    overflow: hidden;
    img{
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 150px;
    }
  }

  &__button_more {
    padding: 1em 0em 1em 0em;
    display: block;
    color: $base-color;
    font-size: 1em;
    width: auto;
    height: 1em;
    line-height: 1em;
    font-weight: 900;
    text-decoration: none;
    text-align: center;
    font-family: Mulish;
    text-transform: uppercase;
    border-top: 1px solid $silver-color;
    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
    }
  }
  &__subscribe_button{
      border-radius: 25px !important;
    text-transform: uppercase;
    font-family: Poppins;
    font-weight: 400 !important;
    letter-spacing: 1px;
    font-size: 18px !important;
    padding: 3px 28px !important;
    color: #fff;
    background-color: #d2232a;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    text-decoration: none;}
}