.group_title_a {
  width: 100%;
  max-width: $xl;
  display: block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1em;

  h2 {
    margin: 0 0 0.3em 0;
  }

  @include xxx(md, mdd, lg) {
    //max-width: 945px;
  }

  &__title {
    font-size: 1em;
    line-height: 1.2em;
    color: $black-color;
    margin: 0 auto 1em auto;
    position: relative;
    font-family: Poppins;
    font-weight: 600;
  }

  &__bottom_border {
    border-top: 2px solid $base-color;
    margin: 0 auto;
    max-width: 60px;
  }
}

.group_a {
  .article_teaser__title_link {
    width: calc(100% - 1em);
    padding-left: 0.3em;
    padding-top: 0.3em;
    text-decoration: none;
    padding-right: 0.2em;
  }
  .article_supertitle {
    font-size: 0.8em;
    font-family: Merriweather;
    color: white !important;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 0.5em;
  }
  .group_a__box1 .article_teaser__title {
    font-size: 1.4em;
  }
}

div.group_title {
  cursor: auto;
}

