.group_title_b {
  width: 100%;
  max-width: $xl;
  display: block;
  cursor: pointer;
  text-decoration: none;

  @include xxx(md, mdd, lg) {
    //max-width: 945px;
  }

  h2 {
    padding: 0.5em 0 0 0;
    font-size: 1.3em;
    line-height: 1.1em;
    color: $black-color;
    margin: 0;
    position: relative;
    display: block;
    font-family: Poppins;
    text-align: center;
    text-transform: uppercase;
  }

  &__bottom_border{
    border-top: thin solid $base-color;
    margin: 0.5em auto 2em auto;
    max-width: 60px;
  }
}

div.dot {
  &__small {
    width: 4px;
    height: 4px;
    display: inline-block;
    background-color: $base-color;
    border-radius: 2px;
    position: relative;
    top: -4px;
  }

  &__big {
    width: 6px;
    height: 6px;
    display: inline-block;
    background-color: $base-color;
    border-radius: 3px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }
}

div.group_title {
  cursor: auto;
}