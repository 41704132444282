.caricature {
  h4.widget_title{
    display: none;
  }
  .article_teaser__subtitle_text {
    display: none;
  }
  .article_teaser__author_name {
    display: none;
  }

  @include xxx(xs, sm, smm, md, mdd, lg){
    .article_teaser__article_supertitle {
      display: none;
    }
    .article_teaser__veritcal_switchable {
      display: grid;
      grid-template-columns: 40% 1fr;
      grid-template-rows: max-content;
      grid-template-areas: "a b";
      column-gap: 1em;
    }
    .article_teaser__subtitle_text {
      display: block;
    }
    .article_teaser__author_name {
      display: block;
    }
  }

  @include xxx(xs, sm, smm){
    .article_teaser__subtitle_text {
      display: none;
    }
  }
}