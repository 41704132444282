.group_section_ekosistem {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content;
  grid-template-areas: "a a a x";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  margin: 0 auto 1em auto;
  &__box1 { grid-area: a; }
  &__box_x { grid-area: x; }

  @include xxx(xs, sm, smm){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "x";
  }

  @include xxx(md, mdd, lg){
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "a a a"
                         "x x x";
    //max-width: 945px;
  }
}