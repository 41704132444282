.article_teaser_timeline {
  &__horizontal_switchable {
    display: grid;
    grid-template-columns: 90px repeat(2, 1fr);
    grid-template-rows: max-content;
    grid-template-areas: "date img txt";
    column-gap: 1em;
    @include xxx(xs, sm) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: "date"
                           "img"
                           "txt";
    }

    @include xxx(smm, md, mdd, lg) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "date empty"
                           "img txt";
    }
  }

  &__date_box{
    grid-area: date;
    text-align: right;
  }
  &__vertical_box1{
    grid-area: img;
  }
  &__vertical_box2{
    grid-area: txt;
  }

  &__time_holder {
    font-family: Mulish;
    font-weight: 900;
    line-height: 1.2em;
    font-size: 1.2em;
    color: $base_color;
    margin-bottom: 0.3em;
  }

  &__date_holder {
    color: $darker-50-color;
    font-size: 1em;
    line-height: 1.2em;
    margin-bottom: 0.3em;
  }

  &__article {
    position: relative;
    box-sizing: border-box;
    color: $white-color;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
  }

  &__article-image {
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.5s;
    transition-timing-function: ease;
  }

  &__title_link {
    box-sizing: border-box;
    width: 100%;
    font-size: 1em;
    line-height: 1.2em;
    margin: 0;
    padding-top: 0.3em;
    text-decoration: none;
    color: $black-color;
    font-weight: inherit;
    display: block;

    h3 {
      margin: 0;
      padding: 0;
      font-family: Mulish;
      font-weight: 900;
      line-height: 1.1em;
      font-size: 1.1em;
    }

    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
    }
  }

  &__title_text {
    font-family: Mulish;
    font-weight: 900;
    line-height: 1.2em;
    font-size: 1.2em;
    display: block;
    margin-bottom: 0.3em;
  }

  &__subtitle_text {
    line-height: 1.2em;
    font-size: 1em;
    font-family: Mulish;
    font-weight: 100;
  }

  &__author_name {
    color: $darker-50-color;
    font-size: 0.9em;
    padding-top: 0.2em;
    a {
      font-size: 0.9em;
      color: $darker-50-color;
      text-decoration: none;
      cursor: pointer;
      &:hover{
        color: $darker-80-color;
      }
    }
  }

  &__author{
    display: inline-block;
  }

  &__article_supertitle {
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    font-size: 1em;
    display: block;
    max-width: calc(100% - 2em);
    &::first-letter {
      text-transform: uppercase;
    }

    .article_supertitle {
      padding: 0.2em 0.5em;
      display: block;
    }
  }
}