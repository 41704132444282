.footer_holder {
  background-color: $background-dark-color;
}

.footer {
  display: grid;
  gap: 1em;
  grid-template-areas: "a b c d"
                       "e e e e"
                       "f f f f";
  grid-template-columns: 30% 15% 15% 1fr;

  @include xxx(xs, sm, smm, md, mdd) {
    grid-template-areas: "d"
                         "b"
                         "c"
                         "a"
                         "e"
                         "f";
    grid-template-columns: 1fr;
  }

  width: 100%;
  max-width: $xl;
  font-family: Poppins;
  font-weight: 400;;
  width: calc(100% - 2em);
  margin: 0 auto;
  padding-top: 3em;

  &__box {
    &_1 {
      grid-area: a;
    }

    &_2 {
      grid-area: b;
    }

    &_3 {
      grid-area: c;
    }

    &_4 {
      grid-area: d;
    }

    &_5 {
      grid-area: e;
    }

    &_6 {
      grid-area: f;
    }
  }

  &__box {
    &_1 {
      text-align: center;
    }

    &_4 {
      text-align: center;
    }

    &_5 {
      border-top: 1px solid rgba(102, 102, 102, 1);
      color: rgba(174, 174, 174, 1);
      font-weight: bold;
      font-size: 80%;
      text-align: center;
      padding: 1em;
      padding-bottom: 0em;
    }

    @include xxx(xs, sm, smm, md, mdd) {
      &_1, &_2, &_3, &_4, &_5, &_6 {
        text-align: center;
      }
    }
  }

  &__title {
    font-size: 1.2em;
    font-weight: 400;
    font-family: Poppins;
    color: white;
    margin: 0;
  }

  &__list {
    list-style: none;
    padding: 0 0 1em 0;
    @include xxx(xs, sm, smm, md, mdd) {
      display: inline-block;
    }
  }

  &__link {
    display: flex;
    height: 2em;
    align-items: center;
    color: rgba(174, 174, 174, 1);
    text-decoration: none;
    font-size: 0.8em;
    @include xxx(xs, sm, smm, md, mdd) {
      justify-content: center;
    }
  }

  &__extralink {
    display: block;
    text-decoration: none;

    &_text {
      color: $base-color;
      font-size: 1em;
      padding: 0.5em 0 1em 0;
    }
  }

  &__image {
  }

  &__newsletter_title{
    font-family: Poppins;
    font-weight: 400;
    font-size: 0.8em;
    text-align: left;
    color: rgba(174, 174, 174, 1);
    margin-top: 2em;
  }

  .list_inline {
    display: block;
    text-align: center;
    padding:0;
    &__item {
      display: inline-block;
      padding-right: 1em;
      padding-left: 1em;
      margin-top: 0em;
      &:not(:last-child){
        border-right: thin solid rgba(174, 174, 174, 1);
      }
      a {
        font-size: 0.8em;
        line-height: 1em;
        font-family: Poppins;
        color: rgba(174, 174, 174, 1);
        text-decoration: none;
        &:hover {
          color: $base-color;
        }
      }
    }
  }
  &__social_button{
    font-size: 3em;
    margin-top: 0.5em;
    margin-left: 0.1em;
    display: inline-block;
    margin-right: 0.1em;
  }
}