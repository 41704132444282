.menu0_holder {
  width: 100%;
  background-color: $secondary-color;
}

.menu0 {
  width: 100%;
  max-width: $xl;
  font-size: 85%;
  color: white;
  padding: 0;
  margin: 0 auto;
  background-color: $secondary-color;
  @include xxx(lg){
    //max-width: 945px;
  }
  @include xxx(xs, sm, smm, md, mdd) {
    display: none;
  }

  &__list {
    float: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    display: inline-block;
    &:first-child {
      margin: 0 0 0 0.5em;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2em;
    text-decoration: none;
    padding: 0 0.5em;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
}



.menu_header {
  width: 100%;
  background-color: white;
  margin: 0 auto;
  a { color : white; }
  @include xxx(xs, sm, smm, md, mdd) {
    display: none;
  }
  &__content {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
  &__logo {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    img{
      margin-top: 1em;
      margin-bottom: 1em;
      width: 100%;
    }
  }
  &__weather {
    box-sizing: border-box;
    //width: calc(100% - 14em);
    @include xxx(lg, lgg) {
      padding: 0 10%;
    }
  }
  &__ad {
    margin-right: 1em;
  }
}

.menu1_holder{
  background-color: $background-dark-color;
}

.menu1, .menu1Fixed, .menu3 {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: space-between;
  text-transform: uppercase;
  width: 100%;
  max-width: $xl;
  margin: 0 auto;
  background-color: $background-dark-color;

  @include xxx(lg) {
    font-size: 90%;
  }
  @include xxx(xs, sm, smm, md, mdd) {
    display: none;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    display: inline-block;
    &:nth-of-type(1) {
      padding-left: 0.75em;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;
    font-size: 120%;
    height: 2.5em;
    text-decoration: none;
    padding: 0 0.75em;
    @include xxx(lg) {
      padding: 0 0.5em;
    }
    
    &:hover {
      background-color: $base-color;
    }
    
    &_more {
      cursor: pointer;
      border-left: 1px solid darken($base-color, 15%);
      border-right: 1px solid darken($base-color, 15%);
    }
  }
  &__buttons {
    display: flex;
    margin-right: 0.75em;
    justify-content: right;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    //top: 0.25em;
    color: $white-color;
    //font-size: 120%;
    text-decoration: none;
    width: 3em;
    height: 3em;
    i {
      font-size: 120%;
    }
  }

  &__user {
    float: right;
  }
  &__search {
    float: right;
  }
}

.menu1 .menu1,
.menu1Fixed .menu1Fixed {
  position: absolute;
  top: auto;
  font-size: 100% !important;
  padding: 0;
  margin: 0;
  width: auto;
  z-index: 2;

  & ul {
    padding: 0;
    margin: 0;
    & li {
      display: block;
      width: auto;
      & a {
        justify-content: start !important;
        &:hover {
          background: none !important;
          transition: background 0.2s;
        }
        &.menu1, &.menu1Fixed {
          &__link {
            &_active, &_ancestor {
              background: none !important;
            }
          }
        }
      }
    }
  }
}
.menu1Fixed {
 .menu1__link_more {
   border: none !important;
 }
}

.menu1Fixed {
  position: fixed;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  top: 0;
  z-index: 2;
  font-size: 80%;
  border: 0;
  &__buttons {
    margin-left: auto;
  }
  &__home {
    display: inline-block;
    margin: 1em 1em 0.1em;
    width: 10em;
    img {
      width: 100%;
    }
  }
}

.menu2_holder {
  background-color: #e7e7e7;
  border-bottom: thin solid #cacaca;
  height: 40px;
  line-height: 40px;
  //&.home {
  //  background-color: #f4f4f4;
  //}
}
.menu2 {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: $xl;
  padding: 0;
  margin: 0 auto;
  //border-top: 1px solid darken($base-color, 15%);
  //background-color: darken($base-color, 8%);

  @include xxx(xs, sm, smm, md, mdd) {
    display: none;
  }

  &__list {
    padding: 0;
    margin: 0;
    text-align: center;
  }
  &__item {
    display: inline-block;
    &:nth-of-type(1) {
      padding-left: 0.75em;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary-color-80;
    font-weight: normal;
    height: 2em;
    text-decoration: none;
    padding: 0 1em;
    &:hover {
      color: $base-color;
    }
    &_active {
      background: linear-gradient(0deg, darken($base-color, 18%) 0, darken($base-color, 18%) 0.25em, transparent 0.25em, transparent 100%);
    }
  }
}

.menu3 {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  z-index: 3;
  border: 0;
  @include xxx(lg, lgg, xl, xxl) {
     display: none;
  }
  &__buttons {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0 0;
  }
  &__home {
    display: block;
    width: 8em;
    position: relative;
    top: 0.75em;
    img {
      width: 100%;
    }
  }
}

.menu4 {
  position: fixed;
  overflow-y: scroll;
  top: 3em;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $white-color;
  z-index: 2;

  &__btn {
    background-color: $base-color !important;
  }

  &__list {
    list-style-type: none;
    padding: 0 0 1em 1em;

    &_level_1 {
      top: 3em;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: start;
    color: $black-color;
    height: 2em;
    text-decoration: none;
    &_level_1 {
      font-weight: bold;
      font-size: 130%;
    }
    //@each $category, $color in $categories-colors {
    //  &_#{$category}#{&} { color: $color; }
    //}
  }
}

.container {
  @include xxx(xs, sm, smm, md, mdd) {
    padding-top: 3em;
  }
}