.container {
  .opinions_wide {
    &__wrapper {
      padding: 1em;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:   "a";
      grid-template-rows: auto;
      gap: 1em;

      &__box1 {
        grid-area: a;
      }
    }


    &__main_title {
      background-color: $base-color;
      color: white;
      padding: 0;
      text-decoration: none;
      font-family: Poppins;
      font-weight: 700;
      font-size: 1em;
      line-height: 1.4em;
      padding-left: 1em;
      padding-right: 1em;
    }


    &__image {
      width: 100%;
      padding-top: 99%;
      margin: 0;
      border: none;
      background-size: cover;
      border-radius: 50%;
      background-color: white;
    }

    &__image.squill {
      background-size: 65%;
      background-repeat: no-repeat;
      background-color: white;
      background-position: center;
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:"a"
                            "b";
      grid-template-rows: auto;
      gap: 1em;
    }

    &__content_left {
      a {
        display: block;
        width: 120px;
        height: auto;
        margin: 0 auto;
      }
    }

    &__content_right {
      display: block;
    }


    &__title {
      color: $darker-80-color;
      display: block;
      text-decoration: none;
      font-family: Poppins;
      font-weight: 700;
      font-size: 1em;
      line-height: 1.4em;
      margin-left: 2em;
      margin-right: 2em;
      padding-bottom: 0.5em;
      text-align: center;
    }

    &__text {
      font-size: $sm-font;
      color: $darker-80-color;
      display: block;
      text-decoration: none;
    }

    &__link_wrapper {
      &:hover {
        opacity: 0.6;
        text-decoration: none !important;
      }
    }

    &__more {
      padding: 0 1em 1em 1em;
      display: block;
      color: $base-color;
      font-size: 1em;
      width: auto;
      height: 1em;
      line-height: 1em;
      font-weight: 900;
      text-decoration: none;
      margin: 0;
      text-align: center;
      font-family: Mulish;

      &:hover {
        opacity: 0.6;
        text-decoration: none !important;
      }
    }

    .group_title_b {
      padding-left: 1em;
    }
    
    &__button_more {
      padding: 1em 1em 1em 1em;
      display: block;
      color: $base-color;
      font-size: 1em;
      width: auto;
      height: 1em;
      line-height: 1em;
      font-weight: 900;
      text-decoration: none;
      margin: 0 1em;
      text-align: center;
      font-family: Mulish;
      text-transform: uppercase;
      border-top: 1px solid $silver-color;
      &:hover {
        opacity: 0.6;
        text-decoration: none !important;
      }
    }
  }
}