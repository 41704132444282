
.most_read_articles {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas: "a"
                       "b"
                       "c";
  grid-template-rows: max-content;
  gap: 1em;
  margin-bottom: 2em;
  width: 100%;

  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
}