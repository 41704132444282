.group_carousel_holder4 {
  position: relative;
  overflow: hidden;
  width: calc(100%);
  max-width: calc(1280px + 2em);
  margin: 0 auto 1em auto;
  padding: 0em;
  height: 410px !important;
  background-color: white;
  background: #fff;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow .25s;
  -moz-transition: box-shadow .25s;
  -o-transition: box-shadow .25s;
  -webkit-transition: box-shadow .25s;
  cursor: pointer;
  @include xxx(xs, sm) {
    height: 240px !important;
  }
  @include xxx(smm, lg) {
    height: 370px !important;
  }
  
  @include xxx(xs, sm) {
    .article_teaser__article_supertitle {
      display: none;
    }
    
    .article_teaser__title_text {
      font-size: 1em;
      line-height: 1em;
    }
    
    .article_teaser__subtitle_text {
      line-height: 1em;
      font-size: 0.8em;
    }
  }
  
  @include xxx(xs, sm) {
    width: 100%;
    height: calc(630px + 2em) !important;
  }
  
  @include xxx(smm, md) {
    width: 100%;
    height: calc(780px + 2em) !important;
  }
  
  @include xxx(lg) {
    //max-width: calc(945px + 2em);
  }
  
  .carousel-item {
    position: absolute;
    top: 0;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    height: 410px !important;
    background-color: white;
    @include xxx(xs, sm) {
      height: 2900px !important;
    }
    @include xxx(smm) {
      height: 335px !important;
    }
  }
  
  .group_carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);;
    grid-template-rows: max-content;
    grid-template-areas: "a b c d";
    gap: 1em;
    width: calc(100% - 2em);
    max-width: calc(1280px - 2em);
    margin: 0 auto 1em auto;
    padding: 1em;
    
    @include xxx(xs, sm, smm, md) {
      grid-template-columns: repeat(2, 1fr);;
      grid-template-areas: "a b"
                           "c d";
    }
    
    .bottom_overflow {
      width: 100%;
      height: 1em;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      background: white;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.78802) 25%, white 100%, white 100%);
      padding: 0px;
    }
    
    .box_item {
      position: relative;
      height: 370px;
      overflow: hidden;
      @include xxx(xs, sm) {
        height: 290px !important;
      }
      @include xxx(smm) {
        height: 370px !important;
      }
    }
    
    .box1 {
      grid-area: a;
    }
    
    .box2 {
      grid-area: b;
    }
    
    .box3 {
      grid-area: c;
    }
    
    .box4 {
      grid-area: d;
    }
  }
}

.group_carousel.dark {
  background-color: $darker-80-color;
  
  .article_teaser__title_link {
    color: $offwhite-color;
  }
}

.group_carousel.light {
  width: 100%;
  max-width: 1280px;
  padding: 0em;
  background-color: white;
}

.indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7880196023721988) 25%, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%);
  padding: 0px;
  
  .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 1em;
    width: 1em;
    margin: 24px 10px;
    background-color: $base-color-20;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    border-radius: 50%;
    padding: 0px;
  }
  
  .indicator-item.active {
    background-color: $base-color-80;
  }
}