.article{
  &__metadata--author_sidebar {
    display: flex;
    flex-flow: row wrap;
    align-content: start;
    align-items: start;
    justify-content: space-around;
    width: 100%;
  }
  &__metadata--author_main {
    display: none;
    flex-flow: row wrap;
    align-content: start;
    align-items: start;
    justify-content: space-around;
    justify-items: center;
    width: 100%;
    @include xxx(xs, sm, smm, md, mdd){
      display: flex;
    }
  }
  &__author {
    display: block;
    text-align: center;
    font-weight: 600;
    max-width: 50%;
  }
  &__author_image {
    display: inline-block;
    color: $darker-50-color;
    width: 5em;
    height: 5em;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-color: $secondary-color-10;
    overflow: hidden;
  }
  &__author_name {
    box-sizing: border-box;
    color: $black-color;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.3em;
    margin: 0 0 1em 0;
    cursor: pointer;
    &:hover {
      color: $base-color;
    }
    @media screen and (max-width: $lg) {
      zoom: 0.8;
    }
  }
}
