.store_links{
  text-align: center;
  background-color: $base-color-10;
  padding: 1em;
  width: calc(100% - 2em);
  margin-bottom: 2em;
  
  &__title{
    margin-bottom: 1em;
  }
  
  &__price_title{
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1em;
    line-height: 1.2em;
  }
  
  &__old_price{
    font-weight: normal;
    text-decoration: line-through;
  }
  
  &__buy{
    margin-bottom: 1em;
  }
  
  &__login_link{
    color: $base-color;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}