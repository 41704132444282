.search {
  max-width: $xl;
  width: calc(100% - 2em);
  margin: 0 auto;
  &__content {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: auto;
    grid-template-areas: "a b";
    grid-gap: 1em;
    margin: 0 auto;
    padding-top: 2em;

    @include xxx(xs, sm, smm, md) {
      grid-template-columns: 1fr;
      grid-template-areas: "a" "b";
    }
  }

  &__main {
    grid-area: a;
  }

  &__side_column {
    grid-area: b;
  }

  &__heading {
  }

  &__title {
    font-weight: 800;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: #3174af;
  }

  &__form {
    background: $white-color;
    padding: 1em;
    border: 1px solid $darker-50-color;
    text-align: right;
  }

  &__send_button {
    background-color: $black-color !important;
  }

  &__results {
  
  }

  .pagination {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .carousel {
    height: 200px !important;
    overflow: hidden;
    width: calc(100% - 6em);
    float: left;
    position: relative;
  }

  .carousel.big-authors {
    display: none;
    @include breakpoint(md) {
      display: block;
    }
  }

  .carousel.small-authors {
    display: none;
    @include xxx(xs, sm, smm, md) {
      display: block;
    }
  }

  .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 150px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__authors {
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    background: white;
  }

  &__next_author {
    float: left;
    width: 2em;
    height: 150px;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    &:focus{
      border: none;
      outline: none;
    }
    &:hover{
      color: $base-color;
    }
  }

  &__previous_author {
    float: left;
    width: 2em;
    height: 150px;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    &:focus{
      border: none;
      outline: none;
    }
    &:hover{
      color: $base-color;
    }
  }

  &__author {
    float: left;
    width: 25%;
    display: block;
    @include xxx(xs, sm, smm, md) {
      width: 50%;
    }
  }

  &__author_image {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: block;
    margin: 0.5em auto;
    overflow: hidden;
  }

  &__author_name {
    text-align: center;
    color: $base-color;
  }
}