.social {
  text-align: center;

  &__icon_container {
    display: inline-block;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: white;
    @media screen and (min-width: $md) {
      //margin: 0 8% 0.5em 8%;
    }
  }
  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    mask-mode: alpha;
    mask-position: center;
    -webkit-mask-position: left top;


    &:hover {
      cursor: pointer;
      transform: scale(1.25);
      transition: all 0.3s;
    }
    &_facebook {
      mask-image: url(../images/social/hollow-cut-facebook.svg);
      background-color: #3B5998 !important;
    }
    &_twitter {
      mask-image: url(../images/social/hollow-cut-twitter.svg);
      background-color: #1DA1F2 !important;
    }
    &_email {
      mask-image: url(../images/social/hollow-cut-email.svg);
      background-color: #FF970C !important;
    }
    &_viber {
      mask-image: url(../images/social/hollow-cut-viber.svg);
      background-color: #5A40C4 !important;
    }
    &_linkedin {
      mask-image: url(../images/social/hollow-cut-linkedin.svg);
      background-color: #0285BB !important;
    }
    &_facebook-messenger {
      mask-image: url(../images/social/hollow-cut-fbmessanger.svg);
      background-color: #0067F4 !important;
    }
    &_instagram {
      mask-image: url(../images/social/hollow-cut-instagram.svg);
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%) !important;
    }
    // WEbkiT JUNK
    &_facebook { -webkit-mask-image: url(../images/social/hollow-cut-facebook.svg); }
    &_twitter { -webkit-mask-image: url(../images/social/hollow-cut-twitter.svg); }
    &_email { -webkit-mask-image: url(../images/social/hollow-cut-email.svg); }
    &_viber { -webkit-mask-image: url(../images/social/hollow-cut-viber.svg); }
    &_linkedin { -webkit-mask-image: url(../images/social/hollow-cut-linkedin.svg); }
    &_facebook-messenger { -webkit-mask-image: url(../images/social/hollow-cut-fbmessanger.svg); }
    &_instagram { -webkit-mask-image: url(../images/social/hollow-cut-instagram.svg); }
  }

  .viber_mobile {
    display: none;
    @include xxx(xs, sm, smm, md, mdd) {
      display: inline-block;
    }
  }

  .messenger_desktop {
    display: none;
    @include breakpoint(lg) {
      display: inline-block;
    }
  }

  .messenger_mobile {
    display: none;
    @include xxx(xs, sm, smm, md, mdd) {
      display: inline-block;
    }
  }
}