@use "sass:math";

.ekosistem {
  margin-bottom: 2em;

  &__articles_grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin-bottom: 1em;
    @include xxx(xs, sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1em;
      grid-row-gap: 1em;
    }
    @include breakpoint(smm) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1em;
      grid-row-gap: 1em;
    }
  }

  &__content, a { // A overrides article content design
    display: block;
    box-sizing: border-box;
    color: $black-color;
    text-decoration: none;
    width: 100%;
    line-height: 1.2;
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: math.div(100%, 16) * 9;
    margin-bottom: 0.2em;
    background-size: cover;
    .badge {
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 1em;
      line-height: 1em;
      padding: 0.3em 0.5em;
      background-color: $darker-50-color;
      .logo {
        height: 100%;
        max-width: 100%;
      }
    }
  }

  &__title {
    font-size: $sm-font;
    display: inline-block;
  }
}