#modal_play {
  //Media query mixin
  @mixin media($type) {
    @if $type == tablet { /* Small devices (tablets, 768px and up) */
      @media (min-width: 768px) and (max-width: 991px) {
        @content;
      }
    } @else if $type == mediumDesktop { /* Medium devices (desktops, 992px and up) */
      @media (min-width: 992px) and (max-width: 1100px) {
        @content;
      }
    } @else if $type == largeDesktop { /* Large devices (large desktops, 1200px and up) */
      @media (min-width: 1200px) {
        @content;
      }
    }
  }
  
  //Clearfix mixin
  @mixin clearfix() {
    *zoom: 1;
    &:before, &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
      display: block;
      font-size: 0;
      height: 0;
      visibility: hidden;
    }
  }
  
  .disabled {
    color: #666;
    cursor: default;
  }
  
  .show {
    display: inline-block !important;
  }
  
  .player {
    height: 60px;
    margin: 0;
    position: relative;
    width: 100%;
    
    .large-toggle-btn {
      position: relative;
      width: 40px;
      float: left;
      
      .large-play-btn {
        cursor: pointer;
        display: inline-block;
        position: relative;
        &:before {
          content: "\ea3f";
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 2em;
        }
      }
      
      .large-pause-btn {
        cursor: pointer;
        
        display: inline-block;
        position: relative;
        
        &:before {
          content: "\ea40";
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 2em;
        }
      }
    }
    
    //end .play-box
    .info-box {
      float: left;
      width: calc(100% - 120px);
      
      .track-info-box {
        float: left;
        font-size: 12px;
        margin: 0 0 6px 0;
        visibility: hidden;
        width: 100%;
        text-align: center;
        
        .track-title-text {
          display: inline-block;
        }
        
        .audio-time {
          display: inline-block;
          padding: 0 0 0 5px;
          width: 80px;
        }
        
        @include clearfix();
      }
    }
    
    .progress-box {
      float: left;
      min-width: calc(100% - 2em);
      padding-left: 1em;
      padding-right: 1em;
      position: relative;
      
      .progress-cell {
        height: 12px;
        position: relative;
        
        .progress {
          background: #d9d9d9;
          border: 1px solid #d9d9d9;
          height: 8px;
          position: relative;
          width: auto;
          
          .progress-buffer {
            background: $base-color;
            height: 100%;
            width: 0;
          }
          
          .progress-indicator {
            background: #fff;
            border: 1px solid #bebebe;
            border-radius: 3px;
            cursor: pointer;
            height: 10px;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: -2px;
            width: 22px;
          }
        }
      }
    }
    
    //end .prgoress-box
    .controls-box {
      width: 80px;
      float: right;
      
      .previous-track-btn {
        cursor: pointer;
        
        display: inline-block;
        
        &:before {
          content: "\ea42";
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 2em;
        }
      }
      
      .next-track-btn {
        cursor: pointer;
        
        display: inline-block;
        
        &:before {
          content: "\ea43";
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 2em;
        }
      }
    }
    
    @include clearfix();
  }
  
  //end .player
  
  .play-list-row {
    cursor: pointer;
    display: grid;
    gap: 1em;
    grid-template-areas: "a b";
    grid-template-columns: 40px repeat(1, 1fr);
    grid-template-rows: max-content;
    margin: 1em 0 0 0;
    
    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
      
      .track-title .playlist-track {
        opacity: 0.6;
        text-decoration: none;
      }
      
      .track-image-overlay {
        opacity: 0.6;
      }
    }
    
    .track-image {
      grid-area: a;
      
      img {
        height: 40px;
        width: 40px;
      }
    }
    
    .track-title {
      grid-area: b;
    }
    
    .icon-previous2 {
      font-size: 1em;
      line-height: 1.2em;
    }
    
    .track-image {
      height: 40px;
      position: relative;
      width: 40px;
      
      &-overlay {
        background-color: black;
        cursor: pointer;
        ight: 100%;
        left: 0;
        opacity: 0.15;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
      
      .small-play-btn {
        color: white;
        cursor: pointer;
        font-size: 1.2em;
        left: 10px;
        position: absolute;
        top: 12px;
        z-index: 3;
      }
    }
    
    .track-title .playlist-track {
      color: black;
      cursor: pointer;
      font-family: Mulish;
      font-size: 1;
      font-weight: 700;
      line-height: 1.2;
    }
  }
}