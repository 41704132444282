.group_a {
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "a b"
                       "a c";
  gap: 0.5em;
  width: 100%;
  margin-bottom: 2em;
  
  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }

  @include xxx(xs){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c";
    grid-template-rows: auto 1fr;
  }

  @include xxx(sm, smm){
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas: "a a"
                         "b c";
  }
  .box_shadow{
    background-color: transparent;
    background: transparent;
  }
}