.group_vertical_4 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  grid-template-areas: "a"
                       "b"
                       "c"
                       "d"
                       "e"
                       "f";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  padding: 1em;
  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }
  &__box5 { grid-area: e; }
  &__box6 { grid-area: f; }

  @include xxx(xs, sm) {
    .article_teaser__article_supertitle {
      display: none;
    }
  }

  @include xxx(xs, sm, smm) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a b"
                         "c d"
                         "e f";


    .article_teaser__title_text {
      line-height: 1.2em;
      font-size: 1em;
    }
    .article_teaser__title_link {
      padding-top: 0.3em;
    }
  }

  @include xxx(md, mdd, lg){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d"
                         "e"
                         "f";
  }

  &__title{
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    //border-bottom: 1px solid $silver-color;
    background-color: #222;
    font-family: DeloNormalBold;
    position: relative;
    height: 2.2em;
    line-height: 2.3em;
  }
}