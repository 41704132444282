.recommended_articles {
  display: block;
  width: 100%;
  
  &__content { // A overrides article content design
    display: block;
    box-sizing: border-box;
    color: $black-color;
    text-decoration: none;
    width: 100%;
    &:hover {
      opacity: 0.6;
      text-decoration: none !important;
    }
  }

  &__image{
    width: 100%;
    padding-top: 100%;
    margin: 0;
    background-size: cover;
    border-radius: 50%;
  }

   &__title_image{
    margin-bottom: 0.2em;
  }

  &__title {
    font-size: 1em;
    line-height: 1.2em;
    color: black;
    font-family: Mulish;
    font-weight: 700;
    padding-top: 0.5em;
    display: block;
    text-decoration: none;
  }

  &__content_left {
    float: left;
    width: 30%;
    max-width: 75px;
    margin-top: 1em;
  }

  &__content_right {
    float: left;
    width: calc(70% - 1em);
    padding-left: 1em;
    margin-top: 1em;
  }
}