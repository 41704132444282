.supertitle_with_background {
  .article_supertitle {
    font-family: Poppins;
    text-transform: none;
    font-weight: normal;
    background-color: $base-color;
    color: white;
    font-size: 1.2em;
    padding: 0.3em 0.5em;
    display: block;
  }
}

.article_supertitle {
  font-size: 0.9em;
  font-family: Poppins;
  color: $secondary-color-60;
  text-transform: uppercase;
  font-weight: 300;
}