.group_d {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:   "a a e f"
                         "b b e f"
                         "c c e f"
                         "d d e f"
                         "g g g f";
  grid-template-rows: max-content;
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  margin: 0 auto 1em auto;

  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }

  &__box_module1 {
    grid-area: e;
    background-color: $darker-80-color;
    .article_teaser__title_link{
      color: $offwhite-color;
    }
  }
  &__box_module2 {
    grid-area: f;
    @include xxx(xs, sm, smm, md, mdd, lg) {
      display: none;
    }
  }

  @include xxx(xs){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d"
                         "e"
                         "g";
  }

  @include xxx(sm, smm) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a a"
                         "b b"
                         "c c"
                         "d d"
                         "e e"
                         "g g";
    //max-width: 945px;
  }

  @include xxx(md, mdd, lg){
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "a a e"
                         "b b e"
                         "c c e"
                         "d d e"
                         "g g g";
    //max-width: 945px;
  }

}