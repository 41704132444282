// /*! autoprefixer grid: autoplace */
@import "_mixins";
@import "./materializecss.scss";
@import "typography";
@import "fonts";
@import "./cookies";
@import "iprom";
@import "../../menu/main_menu";
@import "../../searchbox/searchbox";
@import "../../search/search";
@import "../../login/login";
@import "../../home/home_page";
@import "../../home/tag";
@import "../../article/article_default";
@import "../../article/article_web-static";
@import "../../article/article_zapeljivke";
@import "../../article/social_icons_mobile";
@import "../../article/social_icons_mobile_footer";
@import "../../article_groups/article_group_a";
@import "../../article_groups/article_group_a_category";
@import "../../article_groups/article_group_b";
@import "../../article_groups/article_group_b2";
@import "../../article_groups/article_group_c";
@import "../../article_groups/article_group_d";
@import "../../article_groups/article_group_e";
@import "../../article_groups/article_group_f";
@import "../../article_groups/article_group_horizontal_4";
@import "../../article_groups/article_group_carousel_3";
@import "../../article_groups/article_group_carousel_4";
@import "../../article_groups/article_group_carousel_vertical_4";
@import "../../article_groups/article_group_vertical_3";
@import "../../article_groups/article_group_vertical_4";
@import "../../article_groups/article_group_vertical_5";
@import "../../article_groups/article_group_vertical_12";
@import "../../article_groups/article_group_section_left";
@import "../../article_groups/article_group_section_right";
@import "../../article_groups/article_group_section_ekosistem";
@import "../../article_groups/group_title_a";
@import "../../article_groups/group_title_b";
@import "../../article_groups/group_title_c";
@import "../../article_groups/article_group_g";
@import "../../article_groups/article_group_h";
@import "../../article_groups/article_group_i";
@import "../../article_groups/article_group_j";
@import "../../article_groups/subscribe";
@import "../../article_groups/subscribe_footer";
@import "../../article_groups/recommended_articles";
@import "../../authors/author";
@import "../../services/last_articles";
@import "../../services/most_read_articles";
@import "../../services/ekosistem";
@import "../../services/esi_article";
@import "../../services/esi_embed_article";
@import "../../services/esi_audio_playlist";
@import "../../services/last_print";
@import "../../common_snippets/weather";
@import "../../common_snippets/article_supertitle";
@import "../../common_snippets/article_teaser";
@import "../../common_snippets/newest_most_read";
@import "../../common_snippets/opinions";
@import "../../common_snippets/opinions_wide";
@import "../../common_snippets/editors_pick";
@import "../../common_snippets/related_articles";
@import "../../common_snippets/tags";
@import "../../common_snippets/newsletter";
@import "../../common_snippets/support_us";
@import "../../common_snippets/article_icons";
@import "../../common_snippets/article_list";
@import "../../common_snippets/social_icons";
@import "../../common_snippets/paginator";
@import "../../common_snippets/paginator_list";
@import "../../common_snippets/paginator_item";
@import "../../common_snippets/pdf";
@import "../../common_snippets/caricature";
@import "../../footer/footer";
@import "../../footer/footer_top";
@import "../../timeline/timeline";
@import "../../timeline/article_teaser_timeline";
@import "../../common_snippets/outbrain";
@import "../../horoscope/zodiac";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "slick_carousel";
@import "../../article/mobile_footer/social_icons_mobile.scss";
@import "../../article/mobile_footer/social_icons_mobile_footer.scss";
@import "react.scss";

html {
  scroll-behavior: smooth;
}

body {
  font-family: Poppins;
  font-size: 1em;
  line-height: 110%;
  margin: 0;
  background-color: $background-base-color;
}

a {
  color:$base-color;
}

.hidden, .hide {
  display: none !important;
}

.removed, .remove {
  postion: absolute;
  transform: translate(-99999px, 0);
}

.invisible {
  visibility: hidden;
}

.noscroll {
  overflow: hidden;
}

.sticky {
  position: sticky;
  top: 6em;
}

.wagtail-userbar {
  display: none !important;
}


#bar24 {
  position: relative;
  width: 100%;
  background-color: white;

  @include xxx(md, mdd) {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
  }
}
.bar24 {
  display: none;
  //box-sizing: border-box;
  max-width: calc(1280px - 2em);
  width: calc(100% - 2em);
  max-height: 100px;
  height: calc(100vw * 0.06 + 1.5em);
  margin: 0 auto 0 auto;
  padding: 1em 1em 0 1em;
  background-color: white;
  @include xxx(lg){
    //max-width: 945px;
  }
  @media (min-width: $lg) {
    display: block;
    padding-bottom: 0.5em;
  }
}

.space_1 {
  height: 1em;
  overflow: hidden;
}
.space_2 {
  height: 2em;
  overflow: hidden;
}
.space_3 {
  height: 3em;
  overflow: hidden;
}
