.group_i {
  position: relative;
  display: block;
  text-decoration: none;
  height: 100%;
  &__answer_title {
    padding: 2em 1em 1em 1em;
    font-family: Merriweather,serif;
    font-weight: 600;
    line-height: 1.3em;
    color: $black-color;
  }
  
  &__answer {
    padding: 0em 1em 5em 1em;
    font-family: Merriweather,serif;
    color:$darker-50-color;
    line-height: 1.3em;
  }
  
  &__bottom{
  
  }
  
  &__bottom, &__top{
    &:hover{
      .group_i__article_title {
        color: $base-color;
      }
    }
  }
  
  &__author_img {
    width: 100px;
    border: 0.5em solid white;
    border-radius: 100px;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    background-color: white;
    position: relative;
    top: -3.5em;
  }
  
  &__author_label {
    font-family: Merriweather,serif;
    color:$darker-50-color;
    line-height: 1.3em;
    font-style: italic;
    text-align: center;
    position: relative;
    top: -3.5em;
  }
  
  &__article_title {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.05em;
    line-height: 1.4em;
    padding-bottom: 0.2em;
    text-align: center;
    max-width: 300px;
    margin: 1em auto 0 auto;
    text-transform: uppercase;
    position: relative;
    top: -3.5em;
  }
}