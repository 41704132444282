.delo-mobile {
  display: none;
    &__button {
      position: fixed;
      bottom: 1em;
    &--audio {
      right: 1em;
    }
    &--fork {
      left: 1em;
    }
    i {
      transform: scale(1.5);
    }
  }
  &__audio_group {
    //padding-top: 50%;
  }
  &__social_group {
   //margin-top: 30%;
  }
}

.social {
  &__icon_container {
     margin: 0.5em;
     transform: scale(1.2);
     i {
       transform: scale(1.25);
     }
     div, a {
       display: block;
       transform: scale(0.8);
     }
  }
}

#modal_social, #modal_audio {
  background: none !important;
  text-align: center !important;
  box-shadow: none !important;
  .article__audio_controls, .social--md {
    padding: 3em 0;
  }

}

//#modal_social, #modal_audio {
//  display: flex;
//
//  background: none !important;
//  text-align: center !important;
//  box-shadow: none !important;
//  .article__audio_controls, .social--md {
//    display: flex;
//    align-content: center;
//    justify-content: space-between;
//    align-items: center;
//    height: 5em;
//  }
//
//}