.group_horizontal_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);;
  grid-template-rows: max-content;
  grid-template-areas: "title title title title"
                       "a b c d";
  gap: 1em;
  width: calc(100% - 4em);
  max-width: calc(1280px - 2em);
  margin: 0 auto 1em auto;
  padding: 1em;

  &__title {
    grid-area: title;
  }

  &__box1 {
    grid-area: a;
  }

  &__box2 {
    grid-area: b;
  }

  &__box3 {
    grid-area: c;
  }

  &__box4 {
    grid-area: d;
  }

  @include xxx(xs) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "title"
                         "a"
                         "b"
                         "c"
                         "d";
  }

  @include xxx(sm, smm) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "title title"
                         "a b"
                         "c d";
  }

  @include xxx(lg) {
    //max-width: calc(945px - 2em);
  }
}

.group_horizontal_4.dark {
  background-color: $darker-80-color;

  .article_teaser__title_link {
    color: $offwhite-color;
  }
}

.group_horizontal_4.light {

}

.group_horizontal_4__title.dark {
  padding: 0;
  margin: 0;
  color: $offwhite-color;
  font-family: "DeloSerifNormalBold";
  line-height: 1.2em;
  font-size: 1.2em;
}

.group_horizontal_4__title.light {
  font-family: "DeloSerifNormalBold";
  line-height: 1.2em;
  font-size: 1.2em;
}