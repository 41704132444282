@import "variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?wuj2o4');
  src:  url('#{$icomoon-font-path}/icomoon.eot?wuj2o4#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?wuj2o4') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?wuj2o4') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?wuj2o4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Artboard-50 {
  &:before {
    content: $icon-Artboard-50;
  }
}
.icon-Artboard-51 {
  &:before {
    content: $icon-Artboard-51;
  }
}
.icon-Artboard-52 {
  &:before {
    content: $icon-Artboard-52;
  }
}
.icon-Artboard-53 {
  &:before {
    content: $icon-Artboard-53;
  }
}
.icon-Artboard-54 {
  &:before {
    content: $icon-Artboard-54;
  }
}
.icon-Artboard-55 {
  &:before {
    content: $icon-Artboard-55;
  }
}
.icon-Artboard-56 {
  &:before {
    content: $icon-Artboard-56;
  }
}
.icon-Artboard-37 {
  &:before {
    content: $icon-Artboard-37;
  }
}
.icon-Artboard-48 {
  &:before {
    content: $icon-Artboard-48;
  }
}
.icon-Artboard-49 {
  &:before {
    content: $icon-Artboard-49;
  }
}
.icon-Artboard-1 {
  &:before {
    content: $icon-Artboard-1;
  }
}
.icon-Artboard-2 {
  &:before {
    content: $icon-Artboard-2;
  }
}
.icon-Artboard-3 {
  &:before {
    content: $icon-Artboard-3;
  }
}
.icon-Artboard-4 {
  &:before {
    content: $icon-Artboard-4;
  }
}
.icon-Artboard-6 {
  &:before {
    content: $icon-Artboard-6;
  }
}
.icon-Artboard-7 {
  &:before {
    content: $icon-Artboard-7;
  }
}
.icon-Artboard-8 {
  &:before {
    content: $icon-Artboard-8;
  }
}
.icon-Artboard-9 {
  &:before {
    content: $icon-Artboard-9;
  }
}
.icon-Artboard-10 {
  &:before {
    content: $icon-Artboard-10;
  }
}
.icon-Artboard-11 {
  &:before {
    content: $icon-Artboard-11;
  }
}
.icon-Artboard-12 {
  &:before {
    content: $icon-Artboard-12;
  }
}
.icon-Artboard-13 {
  &:before {
    content: $icon-Artboard-13;
  }
}
.icon-Artboard-14 {
  &:before {
    content: $icon-Artboard-14;
  }
}
.icon-Artboard-15 {
  &:before {
    content: $icon-Artboard-15;
  }
}
.icon-Artboard-16 {
  &:before {
    content: $icon-Artboard-16;
  }
}
.icon-Artboard-17 {
  &:before {
    content: $icon-Artboard-17;
  }
}
.icon-Artboard-18 {
  &:before {
    content: $icon-Artboard-18;
  }
}
.icon-Artboard-19 {
  &:before {
    content: $icon-Artboard-19;
  }
}
.icon-Artboard-20 {
  &:before {
    content: $icon-Artboard-20;
  }
}
.icon-Artboard-21 {
  &:before {
    content: $icon-Artboard-21;
  }
}
.icon-Artboard-22 {
  &:before {
    content: $icon-Artboard-22;
  }
}
.icon-Artboard-23 {
  &:before {
    content: $icon-Artboard-23;
  }
}
.icon-Artboard-24 {
  &:before {
    content: $icon-Artboard-24;
  }
}
.icon-Artboard-25 {
  &:before {
    content: $icon-Artboard-25;
  }
}
.icon-Artboard-26 {
  &:before {
    content: $icon-Artboard-26;
  }
}
.icon-Artboard-27 {
  &:before {
    content: $icon-Artboard-27;
  }
}
.icon-Artboard-28 {
  &:before {
    content: $icon-Artboard-28;
  }
}
.icon-Artboard-29 {
  &:before {
    content: $icon-Artboard-29;
  }
}
.icon-Artboard-30 {
  &:before {
    content: $icon-Artboard-30;
  }
}
.icon-Artboard-32 {
  &:before {
    content: $icon-Artboard-32;
  }
}
.icon-Artboard-33 {
  &:before {
    content: $icon-Artboard-33;
  }
}
.icon-Artboard-34 {
  &:before {
    content: $icon-Artboard-34;
  }
}
.icon-Artboard-35 {
  &:before {
    content: $icon-Artboard-35;
  }
}
.icon-Artboard-36 {
  &:before {
    content: $icon-Artboard-36;
  }
}
.icon-Artboard-38 {
  &:before {
    content: $icon-Artboard-38;
  }
}
.icon-Artboard-39 {
  &:before {
    content: $icon-Artboard-39;
  }
}
.icon-Artboard-46 {
  &:before {
    content: $icon-Artboard-46;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-home2 {
  &:before {
    content: $icon-home2;
  }
}
.icon-home3 {
  &:before {
    content: $icon-home3;
  }
}
.icon-office {
  &:before {
    content: $icon-office;
  }
}
.icon-newspaper {
  &:before {
    content: $icon-newspaper;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-pencil2 {
  &:before {
    content: $icon-pencil2;
  }
}
.icon-quill {
  &:before {
    content: $icon-quill;
  }
}
.icon-pen {
  &:before {
    content: $icon-pen;
  }
}
.icon-blog {
  &:before {
    content: $icon-blog;
  }
}
.icon-eyedropper {
  &:before {
    content: $icon-eyedropper;
  }
}
.icon-droplet {
  &:before {
    content: $icon-droplet;
  }
}
.icon-paint-format {
  &:before {
    content: $icon-paint-format;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-images {
  &:before {
    content: $icon-images;
  }
}
.icon-camera {
  &:before {
    content: $icon-camera;
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}
.icon-music {
  &:before {
    content: $icon-music;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-film {
  &:before {
    content: $icon-film;
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera;
  }
}
.icon-dice {
  &:before {
    content: $icon-dice;
  }
}
.icon-pacman {
  &:before {
    content: $icon-pacman;
  }
}
.icon-spades {
  &:before {
    content: $icon-spades;
  }
}
.icon-clubs {
  &:before {
    content: $icon-clubs;
  }
}
.icon-diamonds {
  &:before {
    content: $icon-diamonds;
  }
}
.icon-bullhorn {
  &:before {
    content: $icon-bullhorn;
  }
}
.icon-connection {
  &:before {
    content: $icon-connection;
  }
}
.icon-podcast {
  &:before {
    content: $icon-podcast;
  }
}
.icon-feed {
  &:before {
    content: $icon-feed;
  }
}
.icon-mic {
  &:before {
    content: $icon-mic;
  }
}
.icon-book {
  &:before {
    content: $icon-book;
  }
}
.icon-books {
  &:before {
    content: $icon-books;
  }
}
.icon-library {
  &:before {
    content: $icon-library;
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text;
  }
}
.icon-profile {
  &:before {
    content: $icon-profile;
  }
}
.icon-file-empty {
  &:before {
    content: $icon-file-empty;
  }
}
.icon-files-empty {
  &:before {
    content: $icon-files-empty;
  }
}
.icon-file-text2 {
  &:before {
    content: $icon-file-text2;
  }
}
.icon-file-picture {
  &:before {
    content: $icon-file-picture;
  }
}
.icon-file-music {
  &:before {
    content: $icon-file-music;
  }
}
.icon-file-play {
  &:before {
    content: $icon-file-play;
  }
}
.icon-file-video {
  &:before {
    content: $icon-file-video;
  }
}
.icon-file-zip {
  &:before {
    content: $icon-file-zip;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-paste {
  &:before {
    content: $icon-paste;
  }
}
.icon-stack {
  &:before {
    content: $icon-stack;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open;
  }
}
.icon-folder-plus {
  &:before {
    content: $icon-folder-plus;
  }
}
.icon-folder-minus {
  &:before {
    content: $icon-folder-minus;
  }
}
.icon-folder-download {
  &:before {
    content: $icon-folder-download;
  }
}
.icon-folder-upload {
  &:before {
    content: $icon-folder-upload;
  }
}
.icon-price-tag {
  &:before {
    content: $icon-price-tag;
  }
}
.icon-price-tags {
  &:before {
    content: $icon-price-tags;
  }
}
.icon-barcode {
  &:before {
    content: $icon-barcode;
  }
}
.icon-qrcode {
  &:before {
    content: $icon-qrcode;
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-coin-dollar {
  &:before {
    content: $icon-coin-dollar;
  }
}
.icon-coin-euro {
  &:before {
    content: $icon-coin-euro;
  }
}
.icon-coin-pound {
  &:before {
    content: $icon-coin-pound;
  }
}
.icon-coin-yen {
  &:before {
    content: $icon-coin-yen;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator;
  }
}
.icon-lifebuoy {
  &:before {
    content: $icon-lifebuoy;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-phone-hang-up {
  &:before {
    content: $icon-phone-hang-up;
  }
}
.icon-address-book {
  &:before {
    content: $icon-address-book;
  }
}
.icon-envelop {
  &:before {
    content: $icon-envelop;
  }
}
.icon-pushpin {
  &:before {
    content: $icon-pushpin;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-location2 {
  &:before {
    content: $icon-location2;
  }
}
.icon-compass {
  &:before {
    content: $icon-compass;
  }
}
.icon-compass2 {
  &:before {
    content: $icon-compass2;
  }
}
.icon-map {
  &:before {
    content: $icon-map;
  }
}
.icon-map2 {
  &:before {
    content: $icon-map2;
  }
}
.icon-history {
  &:before {
    content: $icon-history;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-clock2 {
  &:before {
    content: $icon-clock2;
  }
}
.icon-alarm {
  &:before {
    content: $icon-alarm;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-printer {
  &:before {
    content: $icon-printer;
  }
}
.icon-keyboard {
  &:before {
    content: $icon-keyboard;
  }
}
.icon-display {
  &:before {
    content: $icon-display;
  }
}
.icon-laptop {
  &:before {
    content: $icon-laptop;
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}
.icon-mobile2 {
  &:before {
    content: $icon-mobile2;
  }
}
.icon-tablet {
  &:before {
    content: $icon-tablet;
  }
}
.icon-tv {
  &:before {
    content: $icon-tv;
  }
}
.icon-drawer {
  &:before {
    content: $icon-drawer;
  }
}
.icon-drawer2 {
  &:before {
    content: $icon-drawer2;
  }
}
.icon-box-add {
  &:before {
    content: $icon-box-add;
  }
}
.icon-box-remove {
  &:before {
    content: $icon-box-remove;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk;
  }
}
.icon-drive {
  &:before {
    content: $icon-drive;
  }
}
.icon-database {
  &:before {
    content: $icon-database;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
  }
}
.icon-redo {
  &:before {
    content: $icon-redo;
  }
}
.icon-undo2 {
  &:before {
    content: $icon-undo2;
  }
}
.icon-redo2 {
  &:before {
    content: $icon-redo2;
  }
}
.icon-forward {
  &:before {
    content: $icon-forward;
  }
}
.icon-reply {
  &:before {
    content: $icon-reply;
  }
}
.icon-bubble {
  &:before {
    content: $icon-bubble;
  }
}
.icon-bubbles {
  &:before {
    content: $icon-bubbles;
  }
}
.icon-bubbles2 {
  &:before {
    content: $icon-bubbles2;
  }
}
.icon-bubble2 {
  &:before {
    content: $icon-bubble2;
  }
}
.icon-bubbles3 {
  &:before {
    content: $icon-bubbles3;
  }
}
.icon-bubbles4 {
  &:before {
    content: $icon-bubbles4;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus;
  }
}
.icon-user-minus {
  &:before {
    content: $icon-user-minus;
  }
}
.icon-user-check {
  &:before {
    content: $icon-user-check;
  }
}
.icon-user-tie {
  &:before {
    content: $icon-user-tie;
  }
}
.icon-quotes-left {
  &:before {
    content: $icon-quotes-left;
  }
}
.icon-quotes-right {
  &:before {
    content: $icon-quotes-right;
  }
}
.icon-hour-glass {
  &:before {
    content: $icon-hour-glass;
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner;
  }
}
.icon-spinner2 {
  &:before {
    content: $icon-spinner2;
  }
}
.icon-spinner3 {
  &:before {
    content: $icon-spinner3;
  }
}
.icon-spinner4 {
  &:before {
    content: $icon-spinner4;
  }
}
.icon-spinner5 {
  &:before {
    content: $icon-spinner5;
  }
}
.icon-spinner6 {
  &:before {
    content: $icon-spinner6;
  }
}
.icon-spinner7 {
  &:before {
    content: $icon-spinner7;
  }
}
.icon-spinner8 {
  &:before {
    content: $icon-spinner8;
  }
}
.icon-spinner9 {
  &:before {
    content: $icon-spinner9;
  }
}
.icon-spinner10 {
  &:before {
    content: $icon-spinner10;
  }
}
.icon-spinner11 {
  &:before {
    content: $icon-spinner11;
  }
}
.icon-binoculars {
  &:before {
    content: $icon-binoculars;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in;
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out;
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge;
  }
}
.icon-shrink {
  &:before {
    content: $icon-shrink;
  }
}
.icon-enlarge2 {
  &:before {
    content: $icon-enlarge2;
  }
}
.icon-shrink2 {
  &:before {
    content: $icon-shrink2;
  }
}
.icon-key {
  &:before {
    content: $icon-key;
  }
}
.icon-key2 {
  &:before {
    content: $icon-key2;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-unlocked {
  &:before {
    content: $icon-unlocked;
  }
}
.icon-wrench {
  &:before {
    content: $icon-wrench;
  }
}
.icon-equalizer {
  &:before {
    content: $icon-equalizer;
  }
}
.icon-equalizer2 {
  &:before {
    content: $icon-equalizer2;
  }
}
.icon-cog {
  &:before {
    content: $icon-cog;
  }
}
.icon-cogs {
  &:before {
    content: $icon-cogs;
  }
}
.icon-hammer {
  &:before {
    content: $icon-hammer;
  }
}
.icon-magic-wand {
  &:before {
    content: $icon-magic-wand;
  }
}
.icon-aid-kit {
  &:before {
    content: $icon-aid-kit;
  }
}
.icon-bug {
  &:before {
    content: $icon-bug;
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}
.icon-stats-dots {
  &:before {
    content: $icon-stats-dots;
  }
}
.icon-stats-bars {
  &:before {
    content: $icon-stats-bars;
  }
}
.icon-stats-bars2 {
  &:before {
    content: $icon-stats-bars2;
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy;
  }
}
.icon-gift {
  &:before {
    content: $icon-gift;
  }
}
.icon-glass {
  &:before {
    content: $icon-glass;
  }
}
.icon-glass2 {
  &:before {
    content: $icon-glass2;
  }
}
.icon-mug {
  &:before {
    content: $icon-mug;
  }
}
.icon-spoon-knife {
  &:before {
    content: $icon-spoon-knife;
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf;
  }
}
.icon-rocket {
  &:before {
    content: $icon-rocket;
  }
}
.icon-meter {
  &:before {
    content: $icon-meter;
  }
}
.icon-meter2 {
  &:before {
    content: $icon-meter2;
  }
}
.icon-hammer2 {
  &:before {
    content: $icon-hammer2;
  }
}
.icon-fire {
  &:before {
    content: $icon-fire;
  }
}
.icon-lab {
  &:before {
    content: $icon-lab;
  }
}
.icon-magnet {
  &:before {
    content: $icon-magnet;
  }
}
.icon-bin {
  &:before {
    content: $icon-bin;
  }
}
.icon-bin2 {
  &:before {
    content: $icon-bin2;
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane;
  }
}
.icon-truck {
  &:before {
    content: $icon-truck;
  }
}
.icon-road {
  &:before {
    content: $icon-road;
  }
}
.icon-accessibility {
  &:before {
    content: $icon-accessibility;
  }
}
.icon-target {
  &:before {
    content: $icon-target;
  }
}
.icon-shield {
  &:before {
    content: $icon-shield;
  }
}
.icon-power {
  &:before {
    content: $icon-power;
  }
}
.icon-switch {
  &:before {
    content: $icon-switch;
  }
}
.icon-power-cord {
  &:before {
    content: $icon-power-cord;
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard;
  }
}
.icon-list-numbered {
  &:before {
    content: $icon-list-numbered;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-list2 {
  &:before {
    content: $icon-list2;
  }
}
.icon-tree {
  &:before {
    content: $icon-tree;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-menu2 {
  &:before {
    content: $icon-menu2;
  }
}
.icon-menu3 {
  &:before {
    content: $icon-menu3;
  }
}
.icon-menu4 {
  &:before {
    content: $icon-menu4;
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud;
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download;
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload;
  }
}
.icon-cloud-check {
  &:before {
    content: $icon-cloud-check;
  }
}
.icon-download2 {
  &:before {
    content: $icon-download2;
  }
}
.icon-upload2 {
  &:before {
    content: $icon-upload2;
  }
}
.icon-download3 {
  &:before {
    content: $icon-download3;
  }
}
.icon-upload3 {
  &:before {
    content: $icon-upload3;
  }
}
.icon-sphere {
  &:before {
    content: $icon-sphere;
  }
}
.icon-earth {
  &:before {
    content: $icon-earth;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye-plus {
  &:before {
    content: $icon-eye-plus;
  }
}
.icon-eye-minus {
  &:before {
    content: $icon-eye-minus;
  }
}
.icon-eye-blocked {
  &:before {
    content: $icon-eye-blocked;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-bookmarks {
  &:before {
    content: $icon-bookmarks;
  }
}
.icon-sun {
  &:before {
    content: $icon-sun;
  }
}
.icon-contrast {
  &:before {
    content: $icon-contrast;
  }
}
.icon-brightness-contrast {
  &:before {
    content: $icon-brightness-contrast;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-heart-broken {
  &:before {
    content: $icon-heart-broken;
  }
}
.icon-man {
  &:before {
    content: $icon-man;
  }
}
.icon-woman {
  &:before {
    content: $icon-woman;
  }
}
.icon-man-woman {
  &:before {
    content: $icon-man-woman;
  }
}
.icon-happy {
  &:before {
    content: $icon-happy;
  }
}
.icon-happy2 {
  &:before {
    content: $icon-happy2;
  }
}
.icon-smile {
  &:before {
    content: $icon-smile;
  }
}
.icon-smile2 {
  &:before {
    content: $icon-smile2;
  }
}
.icon-tongue {
  &:before {
    content: $icon-tongue;
  }
}
.icon-tongue2 {
  &:before {
    content: $icon-tongue2;
  }
}
.icon-sad {
  &:before {
    content: $icon-sad;
  }
}
.icon-sad2 {
  &:before {
    content: $icon-sad2;
  }
}
.icon-wink {
  &:before {
    content: $icon-wink;
  }
}
.icon-wink2 {
  &:before {
    content: $icon-wink2;
  }
}
.icon-grin {
  &:before {
    content: $icon-grin;
  }
}
.icon-grin2 {
  &:before {
    content: $icon-grin2;
  }
}
.icon-cool {
  &:before {
    content: $icon-cool;
  }
}
.icon-cool2 {
  &:before {
    content: $icon-cool2;
  }
}
.icon-angry {
  &:before {
    content: $icon-angry;
  }
}
.icon-angry2 {
  &:before {
    content: $icon-angry2;
  }
}
.icon-evil {
  &:before {
    content: $icon-evil;
  }
}
.icon-evil2 {
  &:before {
    content: $icon-evil2;
  }
}
.icon-shocked {
  &:before {
    content: $icon-shocked;
  }
}
.icon-shocked2 {
  &:before {
    content: $icon-shocked2;
  }
}
.icon-baffled {
  &:before {
    content: $icon-baffled;
  }
}
.icon-baffled2 {
  &:before {
    content: $icon-baffled2;
  }
}
.icon-confused {
  &:before {
    content: $icon-confused;
  }
}
.icon-confused2 {
  &:before {
    content: $icon-confused2;
  }
}
.icon-neutral {
  &:before {
    content: $icon-neutral;
  }
}
.icon-neutral2 {
  &:before {
    content: $icon-neutral2;
  }
}
.icon-hipster {
  &:before {
    content: $icon-hipster;
  }
}
.icon-hipster2 {
  &:before {
    content: $icon-hipster2;
  }
}
.icon-wondering {
  &:before {
    content: $icon-wondering;
  }
}
.icon-wondering2 {
  &:before {
    content: $icon-wondering2;
  }
}
.icon-sleepy {
  &:before {
    content: $icon-sleepy;
  }
}
.icon-sleepy2 {
  &:before {
    content: $icon-sleepy2;
  }
}
.icon-frustrated {
  &:before {
    content: $icon-frustrated;
  }
}
.icon-frustrated2 {
  &:before {
    content: $icon-frustrated2;
  }
}
.icon-crying {
  &:before {
    content: $icon-crying;
  }
}
.icon-crying2 {
  &:before {
    content: $icon-crying2;
  }
}
.icon-point-up {
  &:before {
    content: $icon-point-up;
  }
}
.icon-point-right {
  &:before {
    content: $icon-point-right;
  }
}
.icon-point-down {
  &:before {
    content: $icon-point-down;
  }
}
.icon-point-left {
  &:before {
    content: $icon-point-left;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-notification {
  &:before {
    content: $icon-notification;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-cancel-circle {
  &:before {
    content: $icon-cancel-circle;
  }
}
.icon-blocked {
  &:before {
    content: $icon-blocked;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-checkmark2 {
  &:before {
    content: $icon-checkmark2;
  }
}
.icon-spell-check {
  &:before {
    content: $icon-spell-check;
  }
}
.icon-enter {
  &:before {
    content: $icon-enter;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-play2 {
  &:before {
    content: $icon-play2;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-stop {
  &:before {
    content: $icon-stop;
  }
}
.icon-previous {
  &:before {
    content: $icon-previous;
  }
}
.icon-next {
  &:before {
    content: $icon-next;
  }
}
.icon-backward {
  &:before {
    content: $icon-backward;
  }
}
.icon-forward2 {
  &:before {
    content: $icon-forward2;
  }
}
.icon-play3 {
  &:before {
    content: $icon-play3;
  }
}
.icon-pause2 {
  &:before {
    content: $icon-pause2;
  }
}
.icon-stop2 {
  &:before {
    content: $icon-stop2;
  }
}
.icon-backward2 {
  &:before {
    content: $icon-backward2;
  }
}
.icon-forward3 {
  &:before {
    content: $icon-forward3;
  }
}
.icon-first {
  &:before {
    content: $icon-first;
  }
}
.icon-last {
  &:before {
    content: $icon-last;
  }
}
.icon-previous2 {
  &:before {
    content: $icon-previous2;
  }
}
.icon-next2 {
  &:before {
    content: $icon-next2;
  }
}
.icon-eject {
  &:before {
    content: $icon-eject;
  }
}
.icon-volume-high {
  &:before {
    content: $icon-volume-high;
  }
}
.icon-volume-medium {
  &:before {
    content: $icon-volume-medium;
  }
}
.icon-volume-low {
  &:before {
    content: $icon-volume-low;
  }
}
.icon-volume-mute {
  &:before {
    content: $icon-volume-mute;
  }
}
.icon-volume-mute2 {
  &:before {
    content: $icon-volume-mute2;
  }
}
.icon-volume-increase {
  &:before {
    content: $icon-volume-increase;
  }
}
.icon-volume-decrease {
  &:before {
    content: $icon-volume-decrease;
  }
}
.icon-loop {
  &:before {
    content: $icon-loop;
  }
}
.icon-loop2 {
  &:before {
    content: $icon-loop2;
  }
}
.icon-infinite {
  &:before {
    content: $icon-infinite;
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle;
  }
}
.icon-arrow-up-left {
  &:before {
    content: $icon-arrow-up-left;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-up-right {
  &:before {
    content: $icon-arrow-up-right;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-down-right {
  &:before {
    content: $icon-arrow-down-right;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-down-left {
  &:before {
    content: $icon-arrow-down-left;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-up-left2 {
  &:before {
    content: $icon-arrow-up-left2;
  }
}
.icon-arrow-up2 {
  &:before {
    content: $icon-arrow-up2;
  }
}
.icon-arrow-up-right2 {
  &:before {
    content: $icon-arrow-up-right2;
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2;
  }
}
.icon-arrow-down-right2 {
  &:before {
    content: $icon-arrow-down-right2;
  }
}
.icon-arrow-down2 {
  &:before {
    content: $icon-arrow-down2;
  }
}
.icon-arrow-down-left2 {
  &:before {
    content: $icon-arrow-down-left2;
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2;
  }
}
.icon-circle-up {
  &:before {
    content: $icon-circle-up;
  }
}
.icon-circle-right {
  &:before {
    content: $icon-circle-right;
  }
}
.icon-circle-down {
  &:before {
    content: $icon-circle-down;
  }
}
.icon-circle-left {
  &:before {
    content: $icon-circle-left;
  }
}
.icon-tab {
  &:before {
    content: $icon-tab;
  }
}
.icon-move-up {
  &:before {
    content: $icon-move-up;
  }
}
.icon-move-down {
  &:before {
    content: $icon-move-down;
  }
}
.icon-sort-alpha-asc {
  &:before {
    content: $icon-sort-alpha-asc;
  }
}
.icon-sort-alpha-desc {
  &:before {
    content: $icon-sort-alpha-desc;
  }
}
.icon-sort-numeric-asc {
  &:before {
    content: $icon-sort-numeric-asc;
  }
}
.icon-sort-numberic-desc {
  &:before {
    content: $icon-sort-numberic-desc;
  }
}
.icon-sort-amount-asc {
  &:before {
    content: $icon-sort-amount-asc;
  }
}
.icon-sort-amount-desc {
  &:before {
    content: $icon-sort-amount-desc;
  }
}
.icon-command {
  &:before {
    content: $icon-command;
  }
}
.icon-shift {
  &:before {
    content: $icon-shift;
  }
}
.icon-ctrl {
  &:before {
    content: $icon-ctrl;
  }
}
.icon-opt {
  &:before {
    content: $icon-opt;
  }
}
.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked;
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked;
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked;
  }
}
.icon-radio-checked2 {
  &:before {
    content: $icon-radio-checked2;
  }
}
.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked;
  }
}
.icon-crop {
  &:before {
    content: $icon-crop;
  }
}
.icon-make-group {
  &:before {
    content: $icon-make-group;
  }
}
.icon-ungroup {
  &:before {
    content: $icon-ungroup;
  }
}
.icon-scissors {
  &:before {
    content: $icon-scissors;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-font {
  &:before {
    content: $icon-font;
  }
}
.icon-ligature {
  &:before {
    content: $icon-ligature;
  }
}
.icon-ligature2 {
  &:before {
    content: $icon-ligature2;
  }
}
.icon-text-height {
  &:before {
    content: $icon-text-height;
  }
}
.icon-text-width {
  &:before {
    content: $icon-text-width;
  }
}
.icon-font-size {
  &:before {
    content: $icon-font-size;
  }
}
.icon-bold {
  &:before {
    content: $icon-bold;
  }
}
.icon-underline {
  &:before {
    content: $icon-underline;
  }
}
.icon-italic {
  &:before {
    content: $icon-italic;
  }
}
.icon-strikethrough {
  &:before {
    content: $icon-strikethrough;
  }
}
.icon-omega {
  &:before {
    content: $icon-omega;
  }
}
.icon-sigma {
  &:before {
    content: $icon-sigma;
  }
}
.icon-page-break {
  &:before {
    content: $icon-page-break;
  }
}
.icon-superscript {
  &:before {
    content: $icon-superscript;
  }
}
.icon-subscript {
  &:before {
    content: $icon-subscript;
  }
}
.icon-superscript2 {
  &:before {
    content: $icon-superscript2;
  }
}
.icon-subscript2 {
  &:before {
    content: $icon-subscript2;
  }
}
.icon-text-color {
  &:before {
    content: $icon-text-color;
  }
}
.icon-pagebreak {
  &:before {
    content: $icon-pagebreak;
  }
}
.icon-clear-formatting {
  &:before {
    content: $icon-clear-formatting;
  }
}
.icon-table {
  &:before {
    content: $icon-table;
  }
}
.icon-table2 {
  &:before {
    content: $icon-table2;
  }
}
.icon-insert-template {
  &:before {
    content: $icon-insert-template;
  }
}
.icon-pilcrow {
  &:before {
    content: $icon-pilcrow;
  }
}
.icon-ltr {
  &:before {
    content: $icon-ltr;
  }
}
.icon-rtl {
  &:before {
    content: $icon-rtl;
  }
}
.icon-section {
  &:before {
    content: $icon-section;
  }
}
.icon-paragraph-left {
  &:before {
    content: $icon-paragraph-left;
  }
}
.icon-paragraph-center {
  &:before {
    content: $icon-paragraph-center;
  }
}
.icon-paragraph-right {
  &:before {
    content: $icon-paragraph-right;
  }
}
.icon-paragraph-justify {
  &:before {
    content: $icon-paragraph-justify;
  }
}
.icon-indent-increase {
  &:before {
    content: $icon-indent-increase;
  }
}
.icon-indent-decrease {
  &:before {
    content: $icon-indent-decrease;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-new-tab {
  &:before {
    content: $icon-new-tab;
  }
}
.icon-embed {
  &:before {
    content: $icon-embed;
  }
}
.icon-embed2 {
  &:before {
    content: $icon-embed2;
  }
}
.icon-terminal {
  &:before {
    content: $icon-terminal;
  }
}
.icon-share2 {
  &:before {
    content: $icon-share2;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-mail2 {
  &:before {
    content: $icon-mail2;
  }
}
.icon-mail3 {
  &:before {
    content: $icon-mail3;
  }
}
.icon-mail4 {
  &:before {
    content: $icon-mail4;
  }
}
.icon-amazon {
  &:before {
    content: $icon-amazon;
  }
}
.icon-google {
  &:before {
    content: $icon-google;
  }
}
.icon-google2 {
  &:before {
    content: $icon-google2;
  }
}
.icon-google3 {
  &:before {
    content: $icon-google3;
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus;
  }
}
.icon-google-plus2 {
  &:before {
    content: $icon-google-plus2;
  }
}
.icon-google-plus3 {
  &:before {
    content: $icon-google-plus3;
  }
}
.icon-hangouts {
  &:before {
    content: $icon-hangouts;
  }
}
.icon-google-drive {
  &:before {
    content: $icon-google-drive;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-facebook2 {
  &:before {
    content: $icon-facebook2;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-spotify {
  &:before {
    content: $icon-spotify;
  }
}
.icon-telegram {
  &:before {
    content: $icon-telegram;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-vine {
  &:before {
    content: $icon-vine;
  }
}
.icon-vk {
  &:before {
    content: $icon-vk;
  }
}
.icon-renren {
  &:before {
    content: $icon-renren;
  }
}
.icon-sina-weibo {
  &:before {
    content: $icon-sina-weibo;
  }
}
.icon-rss {
  &:before {
    content: $icon-rss;
  }
}
.icon-rss2 {
  &:before {
    content: $icon-rss2;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-youtube2 {
  &:before {
    content: $icon-youtube2;
  }
}
.icon-twitch {
  &:before {
    content: $icon-twitch;
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo;
  }
}
.icon-vimeo2 {
  &:before {
    content: $icon-vimeo2;
  }
}
.icon-lanyrd {
  &:before {
    content: $icon-lanyrd;
  }
}
.icon-flickr {
  &:before {
    content: $icon-flickr;
  }
}
.icon-flickr2 {
  &:before {
    content: $icon-flickr2;
  }
}
.icon-flickr3 {
  &:before {
    content: $icon-flickr3;
  }
}
.icon-flickr4 {
  &:before {
    content: $icon-flickr4;
  }
}
.icon-dribbble {
  &:before {
    content: $icon-dribbble;
  }
}
.icon-behance {
  &:before {
    content: $icon-behance;
  }
}
.icon-behance2 {
  &:before {
    content: $icon-behance2;
  }
}
.icon-deviantart {
  &:before {
    content: $icon-deviantart;
  }
}
.icon-500px {
  &:before {
    content: $icon-500px;
  }
}
.icon-steam {
  &:before {
    content: $icon-steam;
  }
}
.icon-steam2 {
  &:before {
    content: $icon-steam2;
  }
}
.icon-dropbox {
  &:before {
    content: $icon-dropbox;
  }
}
.icon-onedrive {
  &:before {
    content: $icon-onedrive;
  }
}
.icon-github {
  &:before {
    content: $icon-github;
  }
}
.icon-npm {
  &:before {
    content: $icon-npm;
  }
}
.icon-basecamp {
  &:before {
    content: $icon-basecamp;
  }
}
.icon-trello {
  &:before {
    content: $icon-trello;
  }
}
.icon-wordpress {
  &:before {
    content: $icon-wordpress;
  }
}
.icon-joomla {
  &:before {
    content: $icon-joomla;
  }
}
.icon-ello {
  &:before {
    content: $icon-ello;
  }
}
.icon-blogger {
  &:before {
    content: $icon-blogger;
  }
}
.icon-blogger2 {
  &:before {
    content: $icon-blogger2;
  }
}
.icon-tumblr {
  &:before {
    content: $icon-tumblr;
  }
}
.icon-tumblr2 {
  &:before {
    content: $icon-tumblr2;
  }
}
.icon-yahoo {
  &:before {
    content: $icon-yahoo;
  }
}
.icon-yahoo2 {
  &:before {
    content: $icon-yahoo2;
  }
}
.icon-tux {
  &:before {
    content: $icon-tux;
  }
}
.icon-appleinc {
  &:before {
    content: $icon-appleinc;
  }
}
.icon-finder {
  &:before {
    content: $icon-finder;
  }
}
.icon-android {
  &:before {
    content: $icon-android;
  }
}
.icon-windows {
  &:before {
    content: $icon-windows;
  }
}
.icon-windows8 {
  &:before {
    content: $icon-windows8;
  }
}
.icon-soundcloud {
  &:before {
    content: $icon-soundcloud;
  }
}
.icon-soundcloud2 {
  &:before {
    content: $icon-soundcloud2;
  }
}
.icon-skype {
  &:before {
    content: $icon-skype;
  }
}
.icon-reddit {
  &:before {
    content: $icon-reddit;
  }
}
.icon-hackernews {
  &:before {
    content: $icon-hackernews;
  }
}
.icon-wikipedia {
  &:before {
    content: $icon-wikipedia;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2;
  }
}
.icon-lastfm {
  &:before {
    content: $icon-lastfm;
  }
}
.icon-lastfm2 {
  &:before {
    content: $icon-lastfm2;
  }
}
.icon-delicious {
  &:before {
    content: $icon-delicious;
  }
}
.icon-stumbleupon {
  &:before {
    content: $icon-stumbleupon;
  }
}
.icon-stumbleupon2 {
  &:before {
    content: $icon-stumbleupon2;
  }
}
.icon-stackoverflow {
  &:before {
    content: $icon-stackoverflow;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-pinterest2 {
  &:before {
    content: $icon-pinterest2;
  }
}
.icon-xing {
  &:before {
    content: $icon-xing;
  }
}
.icon-xing2 {
  &:before {
    content: $icon-xing2;
  }
}
.icon-flattr {
  &:before {
    content: $icon-flattr;
  }
}
.icon-foursquare {
  &:before {
    content: $icon-foursquare;
  }
}
.icon-yelp {
  &:before {
    content: $icon-yelp;
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal;
  }
}
.icon-chrome {
  &:before {
    content: $icon-chrome;
  }
}
.icon-firefox {
  &:before {
    content: $icon-firefox;
  }
}
.icon-IE {
  &:before {
    content: $icon-IE;
  }
}
.icon-edge {
  &:before {
    content: $icon-edge;
  }
}
.icon-safari {
  &:before {
    content: $icon-safari;
  }
}
.icon-opera {
  &:before {
    content: $icon-opera;
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf;
  }
}
.icon-file-openoffice {
  &:before {
    content: $icon-file-openoffice;
  }
}
.icon-file-word {
  &:before {
    content: $icon-file-word;
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel;
  }
}
.icon-libreoffice {
  &:before {
    content: $icon-libreoffice;
  }
}
.icon-html-five {
  &:before {
    content: $icon-html-five;
  }
}
.icon-html-five2 {
  &:before {
    content: $icon-html-five2;
  }
}
.icon-css3 {
  &:before {
    content: $icon-css3;
  }
}
.icon-git {
  &:before {
    content: $icon-git;
  }
}
.icon-codepen {
  &:before {
    content: $icon-codepen;
  }
}
.icon-svg {
  &:before {
    content: $icon-svg;
  }
}
.icon-IcoMoon {
  &:before {
    content: $icon-IcoMoon;
  }
}

