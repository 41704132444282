.pdf {
  &__main_title {
    background-color: $base-color;
    color: white;
    font-size: 1.2em;
    padding: 0;
    width: auto;
    height: 2.2em;
    line-height: 2.2em;
    font-weight: 600;
    text-decoration: none;
    margin: 1em 0;
    text-align: center;
    font-family: DeloNormalBold;
    &:hover {
      background-color: $base-color-90;
      text-decoration: none;
    }
  }
  &__link {
    color: $white-color;
    text-decoration: none;
  }
  @include xxx(xs, sm, smm, md, mdd, lg, lgg) { display: none; }
}