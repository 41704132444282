.group_c {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content;
  gap: 2em;
  width: 100%;
  max-width: $xl;
  margin: 0 auto;
  padding-bottom: 2em;

  
  @include xxx(xs, sm) {
    grid-template-columns: repeat(1, 1fr);;
  }
  
  &__horizontal_switchable {
    display: grid;
    grid-template-columns: 33% 1fr;
    grid-template-rows: max-content;
    grid-template-areas: "a b";
    column-gap: 1em;
    @include xxx(xs, sm) {
      grid-template-columns: 40% 1fr;
    }
  }
  
  &__vertical_switchable {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: max-content;
    grid-template-areas: "a b c d";
    column-gap: 1em;
    @include xxx(xs) {
      grid-template-columns: repeat(1, 1fr);;
      grid-template-areas: "a"
                            "b"
                           "c"
                            "d";
    }
    @include xxx(sm, smm, md, mdd) {
      grid-template-columns: repeat(2, 1fr);;
      grid-template-areas: "a b"
                            "c d";
    }
  }
}