.searchbox {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 2em;
  background-color: white;

  &__container {
    position: relative;

    padding-top: 1em;
  }
  .input-field {
    margin: 0;
  }
  @include xxx(xs, sm, smm, md, mdd) {
      &__container {
        padding-top: 4em;
        margin-bottom: -3em; // to counter main_menu.scss l:343
      }
  }
}