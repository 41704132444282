.group_f {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content;
  gap: 2em;
  width: 100%;
  max-width: $xl;
  margin: 0 auto;
  padding-bottom: 2em;
  
  @include xxx(smm, md, mdd) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @include xxx(xs, sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
#load_more_category_archive{
  .show_more{
    text-align: center;
    margin: 0 auto;
    width: 300px;
    display: block;
  }
}