.home_page_wrapper1 .zodiac {
  &__item {
    @include xxx(xl, xxl) {
      width: calc(100% / 3);
    }
  }
}

.zodiac {
  //padding: 0 1em;

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 1em;
    margin-bottom: 2em;
  }
  &__item {
    display: block;
    margin-bottom: 1em;
    width: calc(100% / 3);
    @include xxx(xl, xxl) {
      width: calc(100% / 12);
      //height: 6em;
    }
    @include xxx(md, mdd, lg, lgg) {
      width: calc(100% / 6);
      //height: 8em;
    }
    @include xxx(sm, smm) {
      width: calc(100% / 4);
      //height: 10vw;
    }
  }
  &__link {
    text-decoration: none;
  }
  &__name {
    color: $darker-50-color;
    text-align: center;
    text-transform: capitalize;
  }
  &__icon {
    width: 100%;
    height: 7em;
    @include xxx(xl, xxl) {
      height: 8em;
    }
    @include xxx(md, mdd, lg, lgg) {
      height: 6em;
    }
    @include xxx(sm, smm) {
      height: 7em;
    }
    &:hover {
      fill: $base-color;
    }
  }


  &__content {
    display: flex;
    flex-wrap: wrap;
  }
  &__box {
    margin-bottom: 2em;
  }
  &__box1 {
    width:40%;
    @include xxx(xs, sm, smm, md) {
      width: 100%;
      text-align: center;
    }
  }
  &__sign {
    width: 100%;
    height: 15em;

    @include xxx(xs, sm, smm, md) {
      width: 50%;
      height: 8em;
    }
  }
  &__date {
    text-align: center;
    color: $darker-50-color;
    h3 {
      display: inline;
      font-weight: normal;
    }
  }


  &__box2 {
    width: 60%;
    @include xxx(xs, sm, smm, md) {
      width: 100%;
    }
  }

  .tabs_holder {
    background-color: transparent;
  }
  .tab-panel {
    background-color: transparent;
    h3 {
      @include xxx(xs, sm, smm, md) {
        font-size: 160%;
        display: none;
      }
    }
  }
  .tabs {
    .tab {
      a {
        color: black;
        text-transform: uppercase;
        font-weight: 500;
        &:hover {
          color: black;
          background: lightgrey;
        }
        &:focus {
          color: inherit !important;
        }
        &.active {
          color: $base-color !important;
          text-decoration: underline;
        }
      }
    }
  }
}