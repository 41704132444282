.weather {
  display: flex;
  font-family: Mulish, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 110%;
  color: white;

  &__panel {
    display: flex;
    align-items: center;
    align-content: start;
  }
  &__icon {
    display: flex;
    font-size: 400%;
  }
  &__citytemp {
    font-weight: 900;
    font-size: 130%;
    padding: 0 0.5em;
    border-right: 1px solid white;
  }
  &__selector {
    color: white !important;
    margin: 0 !important;
    padding: 0 2em 0 1em !important;
    font-family: Mulish, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: capitalize !important;
    text-align: left !important;
    min-width: 9em;
    span {
      white-space: nowrap;
    }
  }
  &__dropdown {
    position: absolute;
    z-index: 1;
  }

  &__citytommorow {
    white-space: nowrap;
  }


  .fa-sort-down {
    font-size: 1em;
    top: -3px;
    position: relative;
    left: 3px;
  }
  //
  //&__panel {
  //  display: grid;
  //  grid-template-columns: repeat(3, 1fr);;
  //  grid-template-rows: max-content;
  //  grid-template-areas: "a b c";
  //  gap: 1em;
  //  width: 100%;
  //  margin: 0 auto 1em auto;
  //
  //  .weather__cityicon {
  //    grid-area: a;
  //
  //    img {
  //      max-width: 100px;
  //      margin: 0 auto;
  //      display: block;
  //    }
  //  }
  //
  //  .weather__citytemp {
  //    grid-area: b;
  //    font-size: 2em;
  //    //border-left: 1px solid $darker-10-color;
  //    //border-right: 1px solid $darker-10-color;
  //    text-align: center;
  //
  //    .temp_holder {
  //      display: flex;
  //      justify-content: center;
  //      align-items: center;
  //      height: 100%;
  //
  //      span {
  //        position: relative;
  //        left: 0.1em;
  //      }
  //    }
  //  }
  //
  //  .weather__citytommorow {
  //    grid-area: c;
  //
  //    .tomorrow_holder {
  //      display: flex;
  //      justify-content: center;
  //      align-items: center;
  //      height: 100%;
  //
  //      .tomorrow_title {
  //        //font-family: DeloNormal;
  //      }
  //
  //      .tomorrow_value {
  //        //font-family: DeloNormalBold;
  //      }
  //    }
  //  }
  //}
}

.weather.materializecss {
  .dropdown-trigger.btn {
    //font-family: DeloNormalBold;
    font-size: 1em;
    color: #099;
    padding: 0;
    margin: 0 0 0.65em 0;
    height: auto;
    line-height: 1.8;
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .dropdown-content li {
    clear: both;
    color: $black-color;
    cursor: pointer;
    min-height: 1.2em;
    line-height: 1.2em;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0.5em;
  }

  .dropdown-content {
    min-width: 150px;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    font-size: 80%;
  }
}