.group_b {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "a b c";
  grid-template-rows: max-content;
  gap: 2em;
  width: 100%;
  max-width: $xl;
  margin: 0 auto;
  padding-bottom: 2em;
  
  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  
  &__vertical {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-areas: "a"
    "b"
    "c" !important;
  }
  
  @include xxx(xs, sm) {
    grid-template-columns: repeat(1, 1fr);;
    grid-template-areas: "a"
                           "b"
                           "c";
    .article_teaser__veritcal_switchable .article_teaser__title_text {
      font-family: Poppins;
      font-weight: 700;
      font-size: 0.9em;
      line-height: 1.3em;
      margin-bottom: 0.5em;
      text-transform: uppercase;
    }
    .article_teaser__veritcal_switchable .article_teaser__title_link h3 {
      margin: 0;
      padding: 0;
      font-family: Poppins;
      font-weight: 700;
      font-size: 1.05em;
      line-height: 1.4em;
      padding-bottom: 0.2em;
    }
  }
}