#dds-poll {
    --distance: 2rem;

    display: flex;
    flex-direction: column;
    position: relative;
    aspect-ratio: 1;
    background-color: white;
    border: 1px solid rgb(192, 192, 192);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    .titleImage {
        aspect-ratio: calc(1.681 + 1);
        background-size: cover;
        background-position: center;
    }

    .content {
        margin:  0 var(--distance);

        .questionsContainer {
            display: flex;
            gap: var(--distance);

            .question {
                margin-bottom: 1rem;
            }

        }

        .answersContainer {
            .answer {
                --height: 2rem;

                position: relative;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-height: calc(1.5 * var(--height));
                margin-bottom: 1rem;
                background-color: rgba(0,0,0,0.1);
                .gauge {
                    display: block;
                    position: absolute;
                    height: 100%;
                    background-color: rgba(0,0,0,0.25);
                    transition: all 2s ease-in-out;
                }
                .text {
                    margin-left: 1rem;
                    padding: 0.5rem;
                }
                .percentage {
                    margin:  0 1rem 0 auto;
                    font-weight: bold;
                }

                &.current {
                    font-weight: bold;
                }
            }
        }

        .header {
            width: 33.33%;
            .image {
                width: 100%;
                aspect-ratio: calc(1.681);
                background-size: cover;
                background-position: center;
            }
        }
    }

    .buttonbar {
        display: flex;
        margin: auto 2rem 2rem 2rem;
        gap: 0.5rem;
        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            text-decoration: none;
            height: 2.2rem;
            padding: 0 1rem;
            border-radius: 3px;
            transition: opacity 0.3s;
            &:hover {
                opacity: 0.75;
            }
            &:last-child {
                margin-left: auto;
            }
        }
    }

    [type="radio"].sn-radio:checked + span:after {
    background-color: #c60001 !important;
  }

  [type="radio"].sn-radio:checked + span:after,
  [type="radio"].sn-radio:not(:checked) + span:after {
    border: 2px solid #c60001 !important;
  }

  [type="radio"].delo-radio:checked + span:after {
    background-color: #1f5ab1 !important;
  }

  [type="radio"].delo-radio:checked + span:after,
  [type="radio"].delo-radio:not(:checked) + span:before {
    border: 2px solid #1f5ab1 !important;
  }

  [type="radio"].ok-radio:checked + span:after {
    background-color: #9acd32 !important;
  }

  [type="radio"].ok-radio:checked + span:after,
  [type="radio"].ok-radio:not(:checked) + span:before {
    border: 2px solid #9acd32 !important;
  }

    .progressVisualFull {
    display: flex;
    /* change the value if you want different thickness */
    height: 40px;
    margin: 20px 0;
    //border: 1px solid white;
  }

  .progressVisualPart {
    /* Number of the seconds for complete animation */
    transition: width 3s;
  }

  .progressLabel, .progressLabelSelected {
    /* Nothing here, move along */
    padding-left: 10px;
    white-space: nowrap;
    padding-top: 3px;
    display: table-cell;
    color: #222222;
  }

  .progressLabelSelected{
    font-weight: bold !important;
  }

  .progressNumber {
    text-align: right;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 3px;
    white-space: nowrap;
    padding-top: 5px;
    display: table-cell;
    font-size: 0.8em;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.1);
    color: #222222;
  }

  // Christmas
  &.category-2 {
    background-image: url(/static/christmas_bg/red-bokeh-christmas-background.jpg);

    p, h2, div {
      color: white;
    }

    form {
      input {
        border-bottom: 1px solid white !important;
      }

      .form_cb_label {
        color: white !important;
      }

      [type="checkbox"] + span:not(.lever):before {
        border: 2px solid white !important;
      }

      [type="checkbox"]:checked + span:not(.lever):before {
        border-top: 2px solid transparent !important;
        border-left: 2px solid transparent !important;
        border-right: 2px solid white !important;
        border-bottom: 2px solid white !important;
      }
    }



  }
}