.embed_article {
  display: grid;
  position: relative;
  grid-template-columns: 128px auto;
  grid-column-gap: 1em;
  grid-row-gap: 0em;
  width: 100%;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  border-top: 1px solid $darker-10-color;
  border-bottom: 1px solid $darker-10-color;
  & + br { display: none; }

  &__image { // A overrides article content design
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    line-height: 1em;
    margin-right: 1em;
    img {
      display: block;
      line-height: 0em;
    }
  }

  &__text {
    display: flex;
    justify-content: left;
    align-items: center;
    font-style: italic;
    font-size: 1.2em;
  }

  &__title {
    font-size: $sm-font;
    color: $black-color !important;
    padding-right: 1em;
  }

  &__arrow {
    display: block;
    position: absolute;
    bottom: 0em;
    right: 0;
    font-size: 2em;
    color: $darker-50-color !important;
  }
}