.home_page_wrapper1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas: "a a a b";
  gap: 1em 2em;
  width: calc(100% - 2em);
  max-width: $xl;
  margin: 0 auto 2em auto;
  padding-top: 2em;

  .svetovalnica {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "a a"
                          "b c"
                          "d d";
    gap: 1em 1em;
    margin-bottom: 2em;
    &__box1{
      grid-area: a;
    }
    &__box2{
      grid-area: b;
      height: 100%;
    }
    &__box3{
      grid-area: c;
      height: 100%;
    }
    &__box4{
      grid-area: d;
    }

    @include xxx(xs, sm, smm, md, mdd) {
      grid-template-columns: 1fr;
      grid-template-areas: "a"
                            "b"
                            "c"
                            "d";
    }
  
    &__button_more {
      padding: 1em 0em 1em 0em;
      display: block;
      color: #d2232a;
      font-size: 1em;
      width: auto;
      height: 1em;
      line-height: 1em;
      font-weight: 900;
      text-decoration: none;
      margin: 0em;
      text-align: center;
      font-family: Mulish;
      text-transform: uppercase;
    }
  }

  &__main_content {
    grid-area: a;
  }

  &__sidebar {
    grid-area: b;
    @include xxx(xs, sm, smm, md, mdd) {
    }
  }

  @include xxx(xs, sm, smm, md, mdd) {
    grid-template-columns: 1fr;
    grid-template-areas: "a"
                        "b";
  }
}

.home_page_wrapper2 {
  padding-left: 1em;
  padding-right: 1em;
}



