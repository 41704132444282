.group_g {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "a b c d";
  grid-template-rows: max-content;
  gap: 0em;
  width: 100%;
  max-width: $xl;
  margin: 0 auto;

  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }

  @include xxx(smm, md, mdd, lg) {
    grid-template-columns: repeat(2, 1fr);;
    grid-template-areas: "a b"
                           "c d";
  }

  @include xxx(xs, sm) {
    grid-template-columns: repeat(1, 1fr);;
    grid-template-areas: "a"
                           "b"
                           "c"
                            "d";
  }
}