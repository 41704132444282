@use "sass:math";

.editors_pick {
  display: block;
  width: 100%;
  margin-bottom: 1em;

  &__content, a { // A overrides article content design
    display: block;
    box-sizing: border-box;
    color: $black-color;
    text-decoration: none;
    width: 100%;
    line-height: 1.2;
  }

  &__image {
    width: 100%;
    padding-top: math.div(100%, 16) * 9;
    margin-bottom: 0.2em;
    background-size: cover;
  }

  &__title {
    font-size: $sm-font;
    margin-bottom: 1em;
    display: inline-block;
  }
}