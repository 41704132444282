.icon--in_progress {
  display: inline-block;
}

.blinking {
  -webkit-animation: 2s blink ease infinite;
  -moz-animation: 2s blink ease infinite;
  -ms-animation: 2s blink ease infinite;
  -o-animation: 2s blink ease infinite;
  animation: 2s blink ease infinite;

}

@keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.article_icons_holder {
  .article_icons {
    display: inline-block;
    margin: 0.2em 0 0em 0.3em;
    .article_icon {
      max-height: 30px;
      max-width: 30px;
      @include xxx(xs, sm, smm) {
        max-height: 20px;
        max-width: 20px;
      }
    }
  }
}

.article_icons_holder.bottom {
  position: relative;
  display: block;
  .article_icons {
    position: absolute;
    top: 0em;
    right: 0em;
    margin: 0.2em 0 0em 0.3em;
    width: 2em;
  }
}

.article_icons_holder.small_icons {
  .article_icons {
    display: inline-block;
    .article_icon {
      max-width: 25px;
      max-height: 25px;
    }
  }
}

.article_icons_holder.light_icons {
  .article_icon {
    width: 30px;
  }
}

.article_icons_holder.dark_icons {
  .artilce_icon {
    width: 30px;
  }
}

