
// Font sizes
$xxs-font: 0.6em;
$xs-font: 0.85em;
$sm-font: 1em;
$smm-font: 1.2em;
$md-font: 1.5em;
$mdd-font: 1.8em;
$lg-font: 2.0em;
$lgg-font: 2.8em;
$xl-font: 3.5em;


// Colors
$white-color: #fff;
$background-base-color: rgba(244, 244, 244, 1);
$background-dark-color: rgba(34, 34, 34, 1);
$offwhite-color: rgba(238, 238, 238, 1);
$black-color: rgba(0, 0, 0, 1);


$base-color: rgba(210, 35, 42, 1);
$base-color-10: rgba(198, 0, 0, 0.1);
$base-color-20: rgba(198, 0, 0, 0.3);
$base-color-50: rgba(198, 0, 0, 0.5);
$base-color-80: rgba(198, 0, 0, 0.8);
$base-color-90: rgba(198, 0, 0, 0.9);
$base-color-0: rgba(198, 0, 0, 0);

$secondary-color: rgba(0, 0, 0, 1);
$secondary-color-10: rgba(0, 0, 0, 0.1);
$secondary-color-20: rgba(0, 0, 0, 0.3);
$secondary-color-40: rgba(0, 0, 0, 0.4);
$secondary-color-50: rgba(0, 0, 0, 0.5);
$secondary-color-60: rgba(0, 0, 0, 0.6);
$secondary-color-80: rgba(0, 0, 0, 0.8);
$secondary-color-90: rgba(0, 0, 0, 0.9);
$secondary-color-0: rgba(0, 0, 0, 0);

$ternary-color: rgba(255, 204, 0, 1);

// white - lighter
$lighter-10-color: rgba(255, 255, 255, 0.1);
$lighter-20-color: rgba(255, 255, 255, 0.2);
$lighter-30-color: rgba(255, 255, 255, 0.3);
$lighter-50-color: rgba(255, 255, 255, 0.5);
$lighter-80-color: rgba(255, 255, 255, 0.8);
$lighter-90-color: rgba(255, 255, 255, 0.9);

$darker-10-color: rgba(0, 0, 0, 0.1);
$darker-20-color: rgba(0, 0, 0, 0.2);
$darker-30-color: rgba(0, 0, 0, 0.3);
$darker-50-color: rgba(0, 0, 0, 0.5);
$darker-70-color: rgba(0, 0, 0, 0.7);
$darker-80-color: rgba(0, 0, 0, 0.8);

$silver-color: #ebebeb;
$grey-color: #bfbfbf;
$yellow-color: rgba(236, 233, 204, 1);
$silver-color-light: rgb(238, 238, 238);

$green-color: rgba(0, 153, 153, 1);
$green-40-color: rgba(0, 153, 153, 0.4);

$novice-color: #027333;
$gospodarstvo-color: #2E3192;
$lokalno-color: #A71013;
$mnenja-color: #006565;
$sport-color: #00719C;
$kultura-color: #A26930;
$magazin-color: #C70477;
$prosti-cas-color: #999966;
$sobotna-priloga-color: #0B2F79;
$nedelo-color: #EC7A0A;

$categories-colors: (
  novice: $novice-color,
  gospodarstvo: $gospodarstvo-color,
  lokalno: $lokalno-color,
  mnenja: $mnenja-color,
  sport: $sport-color,
  kultura: $kultura-color,
  magazin: $magazin-color,
  prosti-cas: $prosti-cas-color,
  sobotna-priloga: $sobotna-priloga-color,
  nedelo: $nedelo-color
);


@mixin alpha($property, $color, $alpha) {
  #{$property}: rgba($color, $alpha);
}

// Media breakpoints
$xs: 479px;
$sm: 480px;
$smm: 576px;
$md: 768px;
$mdd: 800px;
$lg: 992px;
$lgg: 1024px;
$xl: 1280px;
$xxl: 2000px;

@mixin breakpoint($size) {
  @if $size == xs {
    @media (max-width: $xs) { @content ; }
  }
  @else if $size == sm {
    @media (min-width: $sm) { @content ; }
  }
  @else if $size == smm {
    @media (min-width: $smm) { @content ; }
  }
  @else if $size == md {
    @media (min-width: $md) { @content ; }
  }
  @else if $size == mdd {
    @media (min-width: $mdd)  { @content ; }
  }
  @else if $size == lg {
    @media (min-width: $lg)  { @content ; }
  }
  @else if $size == xl {
    @media (min-width: $xl)  { @content ; }
  }
  @else if $size == xxl {
    @media (min-width: $xxl)  { @content ; }
  }
}

@mixin xxx($sizes...) {
  @each $size in $sizes {
    @if $size == "xs" {
      @media (max-width: $xs) {
        @content ;
      }
    }
    @if $size == "sm" {
      @media only screen and (min-width: $sm) and (max-width: $smm - 1) {
        @content ;
      }
    }
    @if $size == "smm" {
      @media only screen and (min-width: $smm) and (max-width: $md - 1) {
        @content ;
      }
    }
    @if $size == "md" {
      @media only screen and (min-width: $md) and (max-width: $mdd - 1) {
        @content ;
      }
    }
    @if $size == "mdd" {
      @media only screen and (min-width: $mdd) and (max-width: $lg - 1) {
        @content ;
      }
    }
    @if $size == "lg" {
      @media only screen and (min-width: $lg) and (max-width: $xl - 1) {
        @content ;
      }
    }
    @if $size == "xl" {
      @media only screen and (min-width: $xl) and (max-width: $xxl - 1) {
        @content ;
      }
    }
    @if $size == "xxl" {
      @media (min-width: $xxl - 1) {
        @content ;
      }
    }
  }
}


.clear {
  clear: both;
  font-size: 0px;
  display: block;
  height: 0px;
  overflow: hidden;
  line-height: 0px;
}

.clearfix {
  &:after{
    clear: both;
  }
}
