@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.quiz-app {
    width: 99% !important;
    box-sizing: content-box;
    border: 1px solid lightgrey;
    background-color: #fafafa;
    // background-image: url(/static/christmas_bg/red-bokeh-christmas-background.jpg);
    background-size: cover;

    img { width: 100%; }
    .quiz-content { margin: 0 2em; }
    .quiz-description {
        margin-bottom: 1em;
        p { margin: 0; }
    }
    .quiz-question-title {
        font-size: 1.1em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }


    .quiz-question-image {
        display: inline-block;
        vertical-align: top;
        width: 45% !important;
        margin: 0 4% 0 0;
    }
    .quiz-answer-box {
        display: inline-block;
        vertical-align: top;
        width: 50%;
    }
    @media only screen and (max-width: 1220px) {
        .quiz-question-image { width: 100% !important;}
        .quiz-answer-box { width: 100% !important; padding-top: 1em; }
    }
    @media only screen and (max-width: 992px) {
        .quiz-question-image { width: 45% !important;}
        .quiz-answer-box { width: 50% !important; padding-top: 0em; }
    }
    @media only screen and (max-width: 875px) {
        .quiz-question-image { width: 100% !important;}
        .quiz-answer-box { width: 100% !important; padding-top: 1em; }
    }
    @media only screen and (max-width: 768px) {
        .quiz-question-image { width: 45% !important;}
        .quiz-answer-box { width: 50% !important; padding-top: 0em; }
    }
    @media only screen and (max-width: 560px) {
        .quiz-question-image { width: 100% !important;}
        .quiz-answer-box { width: 100% !important; padding-top: 1em; }
    }

    // Defaults
    p, h2, div { color: black; }

    // Christmas
    &.category-2 {
        background-image: url(/static/christmas_bg/red-bokeh-christmas-background.jpg);
        p, h2, div {
            color: white;
        }
        form { 
            input { border-bottom: 1px solid white !important; }
            .form_cb_label { color: white !important; }
            [type="checkbox"] + span:not(.lever):before {
                border: 2px solid white !important;
            }
            [type="checkbox"]:checked + span:not(.lever):before {
                border-top: 2px solid transparent !important;
                border-left: 2px solid transparent !important;
                border-right: 2px solid white !important;
                border-bottom: 2px solid white !important;
            }
         }
        

    }

}





.butt_answer {
    padding: 0.25em 0.5em;
    color: black !important;
    background-color: white;
    margin-bottom: 9px;
    cursor: pointer;
    border: 1px solid lightgrey;
}

.butt_answer:hover {
    background-color: #eaeaea;
}

.answer-mark {
    padding-right: 5px;
    font-weight: bolder;
}

.input {
    margin-top: 3px;
    margin-bottom: 3px;
}

.quiz-delo {
    background-color: #1f5ab1 !important;
    color: white;
    padding: 10px;
}

.quiz-sn {
    background-color: #c60001 !important;
    color: white;
    padding: 10px;
}

.quiz-ok {
    background-color: #9acd32 !important;
    color: white;
    padding: 10px;
}

.form-error {
    color: #c60001;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
}

.quiz-right {
    text-align: right;
}

.form_cb_label {
    padding-left: 10px;
    color: black;
}

.quiz-header img {
    text-align: center;
}

a.terms, a.terms:hover  {
    color: white;
    font-size: 12px;
    padding-left: 6px;
}

.quiz-radio {
    padding: 3px;
    border: 1px solid grey;
    background-color: white;
    margin-bottom: 10px;
}

.quiz-radio [type="radio"]:not(:checked) + span,
.quiz-radio [type="radio"]:checked + span {
    width: 90%;
}

[type="radio"].sn-radio:checked + span:after {
    background-color: #c60001 !important;
}
[type="radio"].sn-radio:checked + span:after,
[type="radio"].sn-radio:not(:checked) + span:before{
    border: 2px solid #c60001 !important;
}

[type="radio"].delo-radio:checked + span:after{
    background-color: #1f5ab1 !important;
}
[type="radio"].delo-radio:checked + span:after,
[type="radio"].delo-radio:not(:checked) + span:before{
    border: 2px solid #1f5ab1 !important;
}

[type="radio"].ok-radio:checked + span:after{
    background-color: #9acd32 !important;
}
[type="radio"].ok-radio:checked + span:after,
[type="radio"].ok-radio:not(:checked) + span:before{
    border: 2px solid #9acd32 !important;
}