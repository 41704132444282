@import "../fonts/icomoon/style";
@import "../fonts/fontawesome/scss/fontawesome";
@import "../fonts/fontawesome/scss/brands";
@import "../fonts/fontawesome/scss/regular";
@import "../fonts/fontawesome/scss/solid";

// Merriweather Sans
@font-face {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/merriweather_sans/MerriweatherSans-Light.ttf') format('truetype');
}

// Merriweather
@font-face {
  font-family: Merriweather;
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/merriweather/Merriweather-Italic.ttf') format('truetype');
}

// Poppins
@font-face {
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/poppins/Poppins-Light.ttf') format('truetype');
}


@font-face {
  font-family: Poppins;
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

// Roboto
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: italic;
  src: url('../fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/roboto/Roboto-Black.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: italic;
  src: url('../fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
}


// Mulish
@font-face {
  font-family: Mulish;
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 200;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-Light.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-Bold.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 800;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/mulish/Mulish-Black.ttf') format('truetype');
}
@font-face {
  font-family: Mulish;
  font-weight: 900;
  font-style: italic;
  src: url('../fonts/mulish/Mulish-BlackItalic.ttf') format('truetype');
}
