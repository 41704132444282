$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-Artboard-50: "\e91f";
$icon-Artboard-51: "\e920";
$icon-Artboard-52: "\e922";
$icon-Artboard-53: "\eb16";
$icon-Artboard-54: "\eb17";
$icon-Artboard-55: "\eb18";
$icon-Artboard-56: "\eb19";
$icon-Artboard-37: "\e904";
$icon-Artboard-48: "\e905";
$icon-Artboard-49: "\eb15";
$icon-Artboard-1: "\e900";
$icon-Artboard-2: "\e901";
$icon-Artboard-3: "\e902";
$icon-Artboard-4: "\e903";
$icon-Artboard-6: "\e906";
$icon-Artboard-7: "\e907";
$icon-Artboard-8: "\e908";
$icon-Artboard-9: "\e909";
$icon-Artboard-10: "\e90a";
$icon-Artboard-11: "\e90b";
$icon-Artboard-12: "\e90c";
$icon-Artboard-13: "\e90d";
$icon-Artboard-14: "\e90e";
$icon-Artboard-15: "\e90f";
$icon-Artboard-16: "\e910";
$icon-Artboard-17: "\e911";
$icon-Artboard-18: "\e912";
$icon-Artboard-19: "\e913";
$icon-Artboard-20: "\e914";
$icon-Artboard-21: "\e915";
$icon-Artboard-22: "\e916";
$icon-Artboard-23: "\e917";
$icon-Artboard-24: "\e918";
$icon-Artboard-25: "\e919";
$icon-Artboard-26: "\e91a";
$icon-Artboard-27: "\e91b";
$icon-Artboard-28: "\e91c";
$icon-Artboard-29: "\e91d";
$icon-Artboard-30: "\e91e";
$icon-Artboard-32: "\e921";
$icon-Artboard-33: "\e923";
$icon-Artboard-34: "\e924";
$icon-Artboard-35: "\e925";
$icon-Artboard-36: "\e926";
$icon-Artboard-38: "\e927";
$icon-Artboard-39: "\e928";
$icon-Artboard-46: "\e929";
$icon-home: "\e92a";
$icon-home2: "\e92b";
$icon-home3: "\e92c";
$icon-office: "\e92d";
$icon-newspaper: "\e92e";
$icon-pencil: "\e92f";
$icon-pencil2: "\e930";
$icon-quill: "\e931";
$icon-pen: "\e932";
$icon-blog: "\e933";
$icon-eyedropper: "\e934";
$icon-droplet: "\e935";
$icon-paint-format: "\e936";
$icon-image: "\e937";
$icon-images: "\e938";
$icon-camera: "\e939";
$icon-headphones: "\e93a";
$icon-music: "\e93b";
$icon-play: "\e93c";
$icon-film: "\e93d";
$icon-video-camera: "\e93e";
$icon-dice: "\e93f";
$icon-pacman: "\e940";
$icon-spades: "\e941";
$icon-clubs: "\e942";
$icon-diamonds: "\e943";
$icon-bullhorn: "\e944";
$icon-connection: "\e945";
$icon-podcast: "\e946";
$icon-feed: "\e947";
$icon-mic: "\e948";
$icon-book: "\e949";
$icon-books: "\e94a";
$icon-library: "\e94b";
$icon-file-text: "\e94c";
$icon-profile: "\e94d";
$icon-file-empty: "\e94e";
$icon-files-empty: "\e94f";
$icon-file-text2: "\e950";
$icon-file-picture: "\e951";
$icon-file-music: "\e952";
$icon-file-play: "\e953";
$icon-file-video: "\e954";
$icon-file-zip: "\e955";
$icon-copy: "\e956";
$icon-paste: "\e957";
$icon-stack: "\e958";
$icon-folder: "\e959";
$icon-folder-open: "\e95a";
$icon-folder-plus: "\e95b";
$icon-folder-minus: "\e95c";
$icon-folder-download: "\e95d";
$icon-folder-upload: "\e95e";
$icon-price-tag: "\e95f";
$icon-price-tags: "\e960";
$icon-barcode: "\e961";
$icon-qrcode: "\e962";
$icon-ticket: "\e963";
$icon-cart: "\e964";
$icon-coin-dollar: "\e965";
$icon-coin-euro: "\e966";
$icon-coin-pound: "\e967";
$icon-coin-yen: "\e968";
$icon-credit-card: "\e969";
$icon-calculator: "\e96a";
$icon-lifebuoy: "\e96b";
$icon-phone: "\e96c";
$icon-phone-hang-up: "\e96d";
$icon-address-book: "\e96e";
$icon-envelop: "\e96f";
$icon-pushpin: "\e970";
$icon-location: "\e971";
$icon-location2: "\e972";
$icon-compass: "\e973";
$icon-compass2: "\e974";
$icon-map: "\e975";
$icon-map2: "\e976";
$icon-history: "\e977";
$icon-clock: "\e978";
$icon-clock2: "\e979";
$icon-alarm: "\e97a";
$icon-bell: "\e97b";
$icon-stopwatch: "\e97c";
$icon-calendar: "\e97d";
$icon-printer: "\e97e";
$icon-keyboard: "\e97f";
$icon-display: "\e980";
$icon-laptop: "\e981";
$icon-mobile: "\e982";
$icon-mobile2: "\e983";
$icon-tablet: "\e984";
$icon-tv: "\e985";
$icon-drawer: "\e986";
$icon-drawer2: "\e987";
$icon-box-add: "\e988";
$icon-box-remove: "\e989";
$icon-download: "\e98a";
$icon-upload: "\e98b";
$icon-floppy-disk: "\e98c";
$icon-drive: "\e98d";
$icon-database: "\e98e";
$icon-undo: "\e98f";
$icon-redo: "\e990";
$icon-undo2: "\e991";
$icon-redo2: "\e992";
$icon-forward: "\e993";
$icon-reply: "\e994";
$icon-bubble: "\e995";
$icon-bubbles: "\e996";
$icon-bubbles2: "\e997";
$icon-bubble2: "\e998";
$icon-bubbles3: "\e999";
$icon-bubbles4: "\e99a";
$icon-user: "\e99b";
$icon-users: "\e99c";
$icon-user-plus: "\e99d";
$icon-user-minus: "\e99e";
$icon-user-check: "\e99f";
$icon-user-tie: "\e9a0";
$icon-quotes-left: "\e9a1";
$icon-quotes-right: "\e9a2";
$icon-hour-glass: "\e9a3";
$icon-spinner: "\e9a4";
$icon-spinner2: "\e9a5";
$icon-spinner3: "\e9a6";
$icon-spinner4: "\e9a7";
$icon-spinner5: "\e9a8";
$icon-spinner6: "\e9a9";
$icon-spinner7: "\e9aa";
$icon-spinner8: "\e9ab";
$icon-spinner9: "\e9ac";
$icon-spinner10: "\e9ad";
$icon-spinner11: "\e9ae";
$icon-binoculars: "\e9af";
$icon-search: "\e9b0";
$icon-zoom-in: "\e9b1";
$icon-zoom-out: "\e9b2";
$icon-enlarge: "\e9b3";
$icon-shrink: "\e9b4";
$icon-enlarge2: "\e9b5";
$icon-shrink2: "\e9b6";
$icon-key: "\e9b7";
$icon-key2: "\e9b8";
$icon-lock: "\e9b9";
$icon-unlocked: "\e9ba";
$icon-wrench: "\e9bb";
$icon-equalizer: "\e9bc";
$icon-equalizer2: "\e9bd";
$icon-cog: "\e9be";
$icon-cogs: "\e9bf";
$icon-hammer: "\e9c0";
$icon-magic-wand: "\e9c1";
$icon-aid-kit: "\e9c2";
$icon-bug: "\e9c3";
$icon-pie-chart: "\e9c4";
$icon-stats-dots: "\e9c5";
$icon-stats-bars: "\e9c6";
$icon-stats-bars2: "\e9c7";
$icon-trophy: "\e9c8";
$icon-gift: "\e9c9";
$icon-glass: "\e9ca";
$icon-glass2: "\e9cb";
$icon-mug: "\e9cc";
$icon-spoon-knife: "\e9cd";
$icon-leaf: "\e9ce";
$icon-rocket: "\e9cf";
$icon-meter: "\e9d0";
$icon-meter2: "\e9d1";
$icon-hammer2: "\e9d2";
$icon-fire: "\e9d3";
$icon-lab: "\e9d4";
$icon-magnet: "\e9d5";
$icon-bin: "\e9d6";
$icon-bin2: "\e9d7";
$icon-briefcase: "\e9d8";
$icon-airplane: "\e9d9";
$icon-truck: "\e9da";
$icon-road: "\e9db";
$icon-accessibility: "\e9dc";
$icon-target: "\e9dd";
$icon-shield: "\e9de";
$icon-power: "\e9df";
$icon-switch: "\e9e0";
$icon-power-cord: "\e9e1";
$icon-clipboard: "\e9e2";
$icon-list-numbered: "\e9e3";
$icon-list: "\e9e4";
$icon-list2: "\e9e5";
$icon-tree: "\e9e6";
$icon-menu: "\e9e7";
$icon-menu2: "\e9e8";
$icon-menu3: "\e9e9";
$icon-menu4: "\e9ea";
$icon-cloud: "\e9eb";
$icon-cloud-download: "\e9ec";
$icon-cloud-upload: "\e9ed";
$icon-cloud-check: "\e9ee";
$icon-download2: "\e9ef";
$icon-upload2: "\e9f0";
$icon-download3: "\e9f1";
$icon-upload3: "\e9f2";
$icon-sphere: "\e9f3";
$icon-earth: "\e9f4";
$icon-link: "\e9f5";
$icon-flag: "\e9f6";
$icon-attachment: "\e9f7";
$icon-eye: "\e9f8";
$icon-eye-plus: "\e9f9";
$icon-eye-minus: "\e9fa";
$icon-eye-blocked: "\e9fb";
$icon-bookmark: "\e9fc";
$icon-bookmarks: "\e9fd";
$icon-sun: "\e9fe";
$icon-contrast: "\e9ff";
$icon-brightness-contrast: "\ea00";
$icon-star-empty: "\ea01";
$icon-star-half: "\ea02";
$icon-star-full: "\ea03";
$icon-heart: "\ea04";
$icon-heart-broken: "\ea05";
$icon-man: "\ea06";
$icon-woman: "\ea07";
$icon-man-woman: "\ea08";
$icon-happy: "\ea09";
$icon-happy2: "\ea0a";
$icon-smile: "\ea0b";
$icon-smile2: "\ea0c";
$icon-tongue: "\ea0d";
$icon-tongue2: "\ea0e";
$icon-sad: "\ea0f";
$icon-sad2: "\ea10";
$icon-wink: "\ea11";
$icon-wink2: "\ea12";
$icon-grin: "\ea13";
$icon-grin2: "\ea14";
$icon-cool: "\ea15";
$icon-cool2: "\ea16";
$icon-angry: "\ea17";
$icon-angry2: "\ea18";
$icon-evil: "\ea19";
$icon-evil2: "\ea1a";
$icon-shocked: "\ea1b";
$icon-shocked2: "\ea1c";
$icon-baffled: "\ea1d";
$icon-baffled2: "\ea1e";
$icon-confused: "\ea1f";
$icon-confused2: "\ea20";
$icon-neutral: "\ea21";
$icon-neutral2: "\ea22";
$icon-hipster: "\ea23";
$icon-hipster2: "\ea24";
$icon-wondering: "\ea25";
$icon-wondering2: "\ea26";
$icon-sleepy: "\ea27";
$icon-sleepy2: "\ea28";
$icon-frustrated: "\ea29";
$icon-frustrated2: "\ea2a";
$icon-crying: "\ea2b";
$icon-crying2: "\ea2c";
$icon-point-up: "\ea2d";
$icon-point-right: "\ea2e";
$icon-point-down: "\ea2f";
$icon-point-left: "\ea30";
$icon-warning: "\ea31";
$icon-notification: "\ea32";
$icon-question: "\ea33";
$icon-plus: "\ea34";
$icon-minus: "\ea35";
$icon-info: "\ea36";
$icon-cancel-circle: "\ea37";
$icon-blocked: "\ea38";
$icon-cross: "\ea39";
$icon-checkmark: "\ea3a";
$icon-checkmark2: "\ea3b";
$icon-spell-check: "\ea3c";
$icon-enter: "\ea3d";
$icon-exit: "\ea3e";
$icon-play2: "\ea3f";
$icon-pause: "\ea40";
$icon-stop: "\ea41";
$icon-previous: "\ea42";
$icon-next: "\ea43";
$icon-backward: "\ea44";
$icon-forward2: "\ea45";
$icon-play3: "\ea46";
$icon-pause2: "\ea47";
$icon-stop2: "\ea48";
$icon-backward2: "\ea49";
$icon-forward3: "\ea4a";
$icon-first: "\ea4b";
$icon-last: "\ea4c";
$icon-previous2: "\ea4d";
$icon-next2: "\ea4e";
$icon-eject: "\ea4f";
$icon-volume-high: "\ea50";
$icon-volume-medium: "\ea51";
$icon-volume-low: "\ea52";
$icon-volume-mute: "\ea53";
$icon-volume-mute2: "\ea54";
$icon-volume-increase: "\ea55";
$icon-volume-decrease: "\ea56";
$icon-loop: "\ea57";
$icon-loop2: "\ea58";
$icon-infinite: "\ea59";
$icon-shuffle: "\ea5a";
$icon-arrow-up-left: "\ea5b";
$icon-arrow-up: "\ea5c";
$icon-arrow-up-right: "\ea5d";
$icon-arrow-right: "\ea5e";
$icon-arrow-down-right: "\ea5f";
$icon-arrow-down: "\ea60";
$icon-arrow-down-left: "\ea61";
$icon-arrow-left: "\ea62";
$icon-arrow-up-left2: "\ea63";
$icon-arrow-up2: "\ea64";
$icon-arrow-up-right2: "\ea65";
$icon-arrow-right2: "\ea66";
$icon-arrow-down-right2: "\ea67";
$icon-arrow-down2: "\ea68";
$icon-arrow-down-left2: "\ea69";
$icon-arrow-left2: "\ea6a";
$icon-circle-up: "\ea6b";
$icon-circle-right: "\ea6c";
$icon-circle-down: "\ea6d";
$icon-circle-left: "\ea6e";
$icon-tab: "\ea6f";
$icon-move-up: "\ea70";
$icon-move-down: "\ea71";
$icon-sort-alpha-asc: "\ea72";
$icon-sort-alpha-desc: "\ea73";
$icon-sort-numeric-asc: "\ea74";
$icon-sort-numberic-desc: "\ea75";
$icon-sort-amount-asc: "\ea76";
$icon-sort-amount-desc: "\ea77";
$icon-command: "\ea78";
$icon-shift: "\ea79";
$icon-ctrl: "\ea7a";
$icon-opt: "\ea7b";
$icon-checkbox-checked: "\ea7c";
$icon-checkbox-unchecked: "\ea7d";
$icon-radio-checked: "\ea7e";
$icon-radio-checked2: "\ea7f";
$icon-radio-unchecked: "\ea80";
$icon-crop: "\ea81";
$icon-make-group: "\ea82";
$icon-ungroup: "\ea83";
$icon-scissors: "\ea84";
$icon-filter: "\ea85";
$icon-font: "\ea86";
$icon-ligature: "\ea87";
$icon-ligature2: "\ea88";
$icon-text-height: "\ea89";
$icon-text-width: "\ea8a";
$icon-font-size: "\ea8b";
$icon-bold: "\ea8c";
$icon-underline: "\ea8d";
$icon-italic: "\ea8e";
$icon-strikethrough: "\ea8f";
$icon-omega: "\ea90";
$icon-sigma: "\ea91";
$icon-page-break: "\ea92";
$icon-superscript: "\ea93";
$icon-subscript: "\ea94";
$icon-superscript2: "\ea95";
$icon-subscript2: "\ea96";
$icon-text-color: "\ea97";
$icon-pagebreak: "\ea98";
$icon-clear-formatting: "\ea99";
$icon-table: "\ea9a";
$icon-table2: "\ea9b";
$icon-insert-template: "\ea9c";
$icon-pilcrow: "\ea9d";
$icon-ltr: "\ea9e";
$icon-rtl: "\ea9f";
$icon-section: "\eaa0";
$icon-paragraph-left: "\eaa1";
$icon-paragraph-center: "\eaa2";
$icon-paragraph-right: "\eaa3";
$icon-paragraph-justify: "\eaa4";
$icon-indent-increase: "\eaa5";
$icon-indent-decrease: "\eaa6";
$icon-share: "\eaa7";
$icon-new-tab: "\eaa8";
$icon-embed: "\eaa9";
$icon-embed2: "\eaaa";
$icon-terminal: "\eaab";
$icon-share2: "\eaac";
$icon-mail: "\eaad";
$icon-mail2: "\eaae";
$icon-mail3: "\eaaf";
$icon-mail4: "\eab0";
$icon-amazon: "\eab1";
$icon-google: "\eab2";
$icon-google2: "\eab3";
$icon-google3: "\eab4";
$icon-google-plus: "\eab5";
$icon-google-plus2: "\eab6";
$icon-google-plus3: "\eab7";
$icon-hangouts: "\eab8";
$icon-google-drive: "\eab9";
$icon-facebook: "\eaba";
$icon-facebook2: "\eabb";
$icon-instagram: "\eabc";
$icon-whatsapp: "\eabd";
$icon-spotify: "\eabe";
$icon-telegram: "\eabf";
$icon-twitter: "\eac0";
$icon-vine: "\eac1";
$icon-vk: "\eac2";
$icon-renren: "\eac3";
$icon-sina-weibo: "\eac4";
$icon-rss: "\eac5";
$icon-rss2: "\eac6";
$icon-youtube: "\eac7";
$icon-youtube2: "\eac8";
$icon-twitch: "\eac9";
$icon-vimeo: "\eaca";
$icon-vimeo2: "\eacb";
$icon-lanyrd: "\eacc";
$icon-flickr: "\eacd";
$icon-flickr2: "\eace";
$icon-flickr3: "\eacf";
$icon-flickr4: "\ead0";
$icon-dribbble: "\ead1";
$icon-behance: "\ead2";
$icon-behance2: "\ead3";
$icon-deviantart: "\ead4";
$icon-500px: "\ead5";
$icon-steam: "\ead6";
$icon-steam2: "\ead7";
$icon-dropbox: "\ead8";
$icon-onedrive: "\ead9";
$icon-github: "\eada";
$icon-npm: "\eadb";
$icon-basecamp: "\eadc";
$icon-trello: "\eadd";
$icon-wordpress: "\eade";
$icon-joomla: "\eadf";
$icon-ello: "\eae0";
$icon-blogger: "\eae1";
$icon-blogger2: "\eae2";
$icon-tumblr: "\eae3";
$icon-tumblr2: "\eae4";
$icon-yahoo: "\eae5";
$icon-yahoo2: "\eae6";
$icon-tux: "\eae7";
$icon-appleinc: "\eae8";
$icon-finder: "\eae9";
$icon-android: "\eaea";
$icon-windows: "\eaeb";
$icon-windows8: "\eaec";
$icon-soundcloud: "\eaed";
$icon-soundcloud2: "\eaee";
$icon-skype: "\eaef";
$icon-reddit: "\eaf0";
$icon-hackernews: "\eaf1";
$icon-wikipedia: "\eaf2";
$icon-linkedin: "\eaf3";
$icon-linkedin2: "\eaf4";
$icon-lastfm: "\eaf5";
$icon-lastfm2: "\eaf6";
$icon-delicious: "\eaf7";
$icon-stumbleupon: "\eaf8";
$icon-stumbleupon2: "\eaf9";
$icon-stackoverflow: "\eafa";
$icon-pinterest: "\eafb";
$icon-pinterest2: "\eafc";
$icon-xing: "\eafd";
$icon-xing2: "\eafe";
$icon-flattr: "\eaff";
$icon-foursquare: "\eb00";
$icon-yelp: "\eb01";
$icon-paypal: "\eb02";
$icon-chrome: "\eb03";
$icon-firefox: "\eb04";
$icon-IE: "\eb05";
$icon-edge: "\eb06";
$icon-safari: "\eb07";
$icon-opera: "\eb08";
$icon-file-pdf: "\eb09";
$icon-file-openoffice: "\eb0a";
$icon-file-word: "\eb0b";
$icon-file-excel: "\eb0c";
$icon-libreoffice: "\eb0d";
$icon-html-five: "\eb0e";
$icon-html-five2: "\eb0f";
$icon-css3: "\eb10";
$icon-git: "\eb11";
$icon-codepen: "\eb12";
$icon-svg: "\eb13";
$icon-IcoMoon: "\eb14";

