.timeline {
  margin: 0 auto;
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: max-content;
  grid-template-areas: "a b";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  @include xxx(xs, sm, smm){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b";
  }
  @include xxx(lg){
    //max-width: 945px;
  }

  &__section{

    .select_date_category{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: max-content;
      grid-template-areas: "a b";
      gap: 1em;
      width: 100%;
      max-width: $xl;
      @include xxx(xs, sm){
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: "a"
                         "b";
      }
      @include breakpoint(xl){
        grid-template-columns: calc(50% + 60px - 1em) 1fr;
      }
      .dropdown-content.select-dropdown {
        li {
          span {
            font-size: 1.2em;
            text-align: left;
            background-color: transparent;
            color: $black-color;
            line-height: 1em;
          }
        }
      }
    }
  }

  &__articles {
    margin-bottom: 2em;
  }

  .pagination {
    margin-bottom: 2em;
    margin-top: 1em;
  }
  
  @include xxx(md, mdd, lg){
    .opinions_wide__wrapper {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:   "a"
                         "b"
                         "c"
                         "d";
    }
    .opinions_wide__content {
      grid-template-columns: 29% 1fr;
      grid-template-areas: "a b";
      grid-template-rows: auto;
    }
  
    .opinions_wide__content_left a {
      display: initial;
    }
  }
}

.dropdown-content.select-dropdown {
  background-color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid $silver-color;
  li {
    span {
      font-size: 1.2em;
      line-height: 2em;
      text-align: center;
    }
  }
}