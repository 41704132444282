#cookieConsent {
  z-index: 9999999;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-sizing: border-box;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 3em;
  padding-top: 3em;

  .cookieconsent_container {
    padding: 2em;
    width: auto;
  }

  .btn, .btn-large, .btn-small {
    margin-bottom: 0.5em;
    @include xxx(xs, sm) {
      width: calc(100% - 2em);
    }
  }
}

.annoying {
  top: 0;
}

.friendly {
  padding-bottom: 0em !important;
  padding-top: 0em !important;
  bottom: 0;
}

.notice-container {
  display: flex;
  align-items: stretch;
  font-size: 85%;
  line-height: 130%;
  box-sizing: border-box;
  border: 1px solid $darker-50-color;
  background-color: $darker-10-color;
  h3{
    margin-bottom: 0.5em;
  }
  .show_more{
    margin-right: 0.25em;
  }
  &__cookie {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    color: $white-color;
    background-color: $darker-50-color;
    padding: 0.5em;
  }
  &__notice {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
  }
  &__buttons {
    display: flex;
    align-items: center;
    padding: 0.5em;
  }
  @include xxx(xs) {
    flex-direction: column;
  }
}

.video-container{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  &__video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}