// @import '../../src/scss/_mixins';

.delotabs {
  .tabs_holder {
    width: calc(100% - 2em);
    padding: 0 1em;
    background-color: $base-color;
  }
  
  .tabs {
    height: 2.2em;
    line-height: 2.2em;
    background-color: transparent;
    
    .tab {
      height: 2.2em;
      line-height: 2.2em;
      text-transform: initial;
      
      a {
        font-family: Mulish;
        color: $lighter-30-color;
        background-color: transparent;
        font-size: 1em;
        padding: 0;
        width: auto;
        height: 2.2em;
        line-height: 2em;
        font-weight: 900;
        text-decoration: none;
      }
      
      a.active {
        color: $lighter-90-color;
        background-color: transparent;
      }
      
      a:hover {
        color: $white-color;
      }
      
      a:focus, .tabs .tab a:focus.active {
        color: $white-color !important;
        background-color: transparent !important;
      }
    }
    
    .indicator {
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: transparent !important;
      will-change: left, right;
    }
  }
  
  .tabs-label-50 {
    width: calc(50% - 1em);
    display: block;
    float: left;
  }
  
  .tabs-label-33 {
    width: calc(33% - 1em);
    display: block;
    float: left;
  }
  
  .tab-spacer {
    width: 1em;
    text-align: center;
    display: block;
    float: left;
    color: $lighter-50-color;
  }
  
  .tab-panel.active {
    display: block;
  }
  
  .tab-panel {
    background-color: $offwhite-color ;
    display: none;
    padding: 1em;
    margin-bottom: 1em;
  }
}