.group_section_left {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content;
  grid-template-areas: "x a a b"
                       "x c d e"
                       "x f f j"
                       "x g g j"
                       "x h h j"
                       "x i i j";
  gap: 1em;
  width: calc(100% - 2em);
  max-width: $xl;
  margin: 0 auto 1em auto;
  &__box1 { grid-area: a; }
  &__box2 { grid-area: b; }
  &__box3 { grid-area: c; }
  &__box4 { grid-area: d; }
  &__box5 { grid-area: e; }

  &__box6 { grid-area: f; }
  &__box7 { grid-area: g; }
  &__box8 { grid-area: h; }
  &__box9 { grid-area: i; }

  &__box_module1 {
    grid-area: j;
    background: $darker-80-color;
    color: $offwhite-color;
    .article_teaser__title_link{
      color: $offwhite-color;
    }
  }
  &__box_x { grid-area: x; }

  @include xxx(xs){
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d"
                         "e"
                         "f"
                         "g"
                         "h"
                         "i"
                         "j"
                         "x";
  }

  @include xxx(sm, smm){
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a a"
                         "b c"
                         "d e"
                         "f f"
                         "g g"
                         "h h"
                         "i i"
                         "j j"
                         "x x";
  }

  @include xxx(md, mdd, lg){
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "a a b"
                         "c d e"
                         "f f j"
                         "g g j"
                         "h h j"
                         "i i j"
                         "x x x";
    //max-width: 945px;
  }
}